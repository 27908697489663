import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        // fontWeight: '500',
        // // borderRadius: '16px',
        // boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        // transition: '.25s all ease',
        // boxSizing: 'border-box',
        // _focus: {
        //   boxShadow: 'none',
        // },
        _active: {
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 1,
          boxShadow: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      // variants: {
      //   outline: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     overflow: 'hidden',
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     borderRadius: '16px',
      //     borderColor: 'brand.900',
      //     _hover: {
      //       bg: 'linear-gradient(to right, #AA915D, #443A25)',
      //       color: 'white',
      //     },
      //   },
      //   outlineTag: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     overflow: 'hidden',
      //     height: 'fit-content',
      //     padding: '0px 10px',
      //     cursor: 'pointer',
      //     fontWeight: '700',
      //     fontSize: { base: '12px', sm: '10px', md: '12px' },
      //     borderRadius: { base: '10px' },
      //     borderColor: '#00000038',
      //     color: '#00000058',
      //     px: 3,
      //     py: 1,
      //   },
      //   outlineTagLarge: {
      //     bg: '#f5f5f5',
      //     border: '1px solid',
      //     overflow: 'hidden',
      //     height: 'fit-content',
      //     padding: '0px 10px',
      //     cursor: 'pointer',
      //     fontWeight: '500',
      //     fontSize: { base: '14px', sm: '12px', md: '14px' },
      //     borderRadius: { base: '10px' },
      //     borderColor: '#00000000',
      //     color: '#0000009C', // 60%
      //     px: 3,
      //     py: 4,
      //   },
      //   outlineBlue: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     overflow: 'hidden',
      //     padding: '0px 10px',
      //     height: { base: '44px', sm: '34px', md: '44px' },
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     borderColor: 'brand.400',
      //     color: 'brand.400',
      //     _hover: {
      //       bg: 'brand.400',
      //       color: 'white',
      //     },
      //   },

      //   outlineBrand: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     overflow: 'hidden',
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     height: { base: '44px', sm: '34px', md: '44px' },
      //     borderColor: 'brand.900',

      //     color: 'brand.900',
      //     _hover: {
      //       bg: 'brand.900',
      //       color: 'white',
      //     },
      //   },
      //   outlineBrandRoundIcon: {
      //     bg: '#F9F9F9',
      //     border: 'none',
      //     overflow: 'hidden',
      //     borderRadius: '50%',
      //     color: 'brand.900',
      //     _hover: {
      //       bg: 'brand.900',
      //       color: 'white',
      //     },
      //   },
      //   outlineBrandSmall: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     fontSize: '12px',
      //     overflow: 'hidden',
      //     borderRadius: { base: '10px' },
      //     height: { base: '34px' },
      //     borderColor: 'brand.900',
      //     color: 'brand.900',
      //     _hover: {
      //       bg: 'brand.900',
      //       color: 'white',
      //     },
      //   },
      //   outlineYellowSmall: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     fontSize: '12px',
      //     overflow: 'hidden',
      //     borderRadius: { base: '10px' },
      //     height: { base: '34px' },
      //     borderColor: 'yellow.100',
      //     color: 'yellow.100',
      //     _hover: {
      //       bg: 'yellow.100',
      //       color: 'white',
      //     },
      //   },
      //   accept: {
      //     fontSize: '14px',
      //     padding: '0px 20px',
      //     height: '30px',
      //     bg: '#05CD9920',
      //     color: 'horizonGreen.500',
      //     _hover: {
      //       bg: 'green.400',
      //       color: 'white',
      //     },
      //     borderRadius: '10px',
      //   },
      //   decline: {
      //     fontSize: '14px',
      //     padding: '0px 20px',
      //     height: '30px',
      //     bg: '#EE5D5020',
      //     color: 'horizonRed.500',
      //     _hover: {
      //       bg: 'red.400',
      //       color: 'white',
      //     },
      //     borderRadius: '10px',
      //   },
      //   brand: (props: any) => ({
      //     bg: mode('brand.400', 'brand.400')(props),
      //     color: 'white',
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     _focus: {
      //       bg: mode('brand.400', 'brand.400')(props),
      //     },
      //     _active: {
      //       bg: mode('brand.300', 'brand.400')(props),
      //     },
      //     _hover: {
      //       bg: mode('brand.300', 'brand.400')(props),
      //     },
      //   }),
      // h1Cta: (props: any) => ({
      //   // bg: mode('brand.900', 'brand.400')(props), lets do it with gradient mode instead:
      //   bg: mode(
      //     'linear-gradient(101deg, #AA915D 20%, #443A25 120%)',
      //     'brand.400',
      //   )(props),
      //   color: 'white',
      //   fontSize: { base: '16px', sm: '12px', md: '16px' },
      //   borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //   height: { base: '44px', sm: '34px', md: '44px' },
      //   _focus: {
      //     bg: mode('brand.900', 'brand.400')(props),
      //   },
      //   _active: {
      //     bg: mode('brand.900', 'brand.400')(props),
      //   },
      //   _hover: {
      //     bg: mode('brand.800', 'brand.400')(props),
      //   },
      // }),
      //   lightBrand: (props: any) => ({
      //     bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
      //     color: mode('brand.500', 'white')(props),
      //     _focus: {
      //       bg: mode('#F2EFFF', 'whiteAlpha.100')(props),
      //     },
      //     _active: {
      //       bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      //     },
      //     _hover: {
      //       bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
      //     },
      //   }),
      //   light: (props: any) => ({
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     height: { base: '44px', sm: '34px', md: '50px' },
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     bg: mode('white', 'whiteAlpha.100')(props),

      //     color: mode('secondaryGray.900', 'white')(props),
      //     _focus: {
      //       bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      //     },
      //     _active: {
      //       bg: mode('secondaryGray.300', 'whiteAlpha.100')(props),
      //     },
      //     _hover: {
      //       bg: mode('secondaryGray.400', 'whiteAlpha.200')(props),
      //     },
      //   }),
      //   action: (props: any) => ({
      //     fontWeight: '500',
      //     cursor: 'pointer',
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     bg: mode('#0000001E', '#0000001E')(props),
      //     color: mode('white', 'white')(props),
      //     _focus: {
      //       bg: mode('brand.400', 'brand.400')(props),
      //     },
      //     _active: { bg: mode('brand.300', 'brand.300')(props) },
      //     _hover: {
      //       bg: mode('gray.400', 'gray.400')(props),
      //     },
      //   }),
      //   actionRed: (props: any) => ({
      //     fontWeight: '500',
      //     cursor: 'pointer',
      //     borderRadius: { base: '16px', sm: '10px', md: '16px' },
      //     bg: mode('#FF4D4F', '#FF4D4F')(props),
      //     color: mode('white', 'white')(props),
      //     _focus: {
      //       bg: mode('#922c2c', '#922c2c')(props),
      //     },
      //     _active: { bg: mode('#922c2c', '#922c2c')(props) },
      //     _hover: {
      //       bg: mode('#922c2c', '#922c2c')(props),
      //     },
      //   }),
      //   underline: (props: any) => ({
      //     fontWeight: '500',
      //     borderRadius: '0px',
      //     textDecoration: 'underline',
      //     bg: 'transparent',
      //     color: mode('brand.300', 'white')(props),
      //     _focus: {
      //       bg: 'transparent',
      //     },
      //     _active: { bg: 'transparent' },
      //     _hover: {
      //       bg: 'transparent',
      //     },
      //   }),
      //   underlineRed: (props: any) => ({
      //     bg: 'transparent',
      //     overflow: 'hidden',
      //     textDecoration: 'underline',
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     borderRadius: props.size === 'sm' ? '10px' : '16px',
      //     color: 'red.500',
      //     transition: 'all .25s ease',
      //     _hover: {
      //       color: 'red.200',
      //       bg: 'gray.200',
      //     },
      //   }),
      //   outlineRed: {
      //     bg: 'transparent',
      //     border: '1px solid',
      //     overflow: 'hidden',
      //     fontSize: { base: '16px', sm: '12px', md: '16px' },
      //     borderRadius: '16px',
      //     borderColor: 'horizonRed.500',
      //     color: 'red.500',
      //     _hover: {
      //       bg: 'red.500',
      //       color: 'white',
      //     },
      //   },
      //   ghostBlue: {
      //     bg: 'transparent',
      //     color: 'brand.600',
      //     _hover: {
      //       bg: 'transparent',
      //       color: 'blue.400',
      //     },
      //     _active: {
      //       bg: 'brand.400',
      //       color: 'transparent',
      //     },
      //   },
      //   setup: (props: any) => ({
      //     fontWeight: '500',
      //     borderRadius: '50px',
      //     bg: mode('transparent', 'brand.400')(props),
      //     border: mode('1px solid', '0px solid')(props),
      //     borderColor: mode('secondaryGray.400', 'transparent')(props),
      //     color: mode('secondaryGray.900', 'white')(props),
      //     _focus: {
      //       bg: mode('transparent', 'brand.400')(props),
      //     },
      //     _active: { bg: mode('transparent', 'brand.400')(props) },
      //     _hover: {
      //       bg: mode('secondaryGray.100', 'brand.400')(props),
      //     },
      //   }),
      //   date: () => ({
      //     bg: mode('transparent', 'navy.800'),
      //     border: '1px solid',
      //     color: 'rgba(0, 0, 0, 0.8)',
      //     borderColor: 'general.100',
      //     borderRadius: '16px',
      //     fontSize: '14px',
      //     fontWeight: '500',
      //     maxH: '50px',
      //     p: '25px',
      //   }),
      //   succsess: (props: any) => ({
      //     h: '44px',
      //     fontSize: '14px',
      //     fontWeight: '500',
      //     bg: 'brand.900',
      //     color: 'white',
      //     borderRadius: '16px',
      //     transition: 'all .25s ease',
      //     pe: '40px',
      //     ps: '40px',
      //     _focus: {
      //       bg: 'brand.900',
      //     },
      //     _active: {
      //       bg: 'brand.800',
      //     },
      //     _hover: {
      //       bg: 'brand.800',
      //     },
      //   }),
      // },
      variants: {
        h1cta: {
          transition: 'all 0.5s linear',
          color: '#FFFFFF',
          fontWeight: '500',
          fontSize: { base: '16px', md: '14px' },
          lineHeight: { base: '16px', md: '14px' },
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          letterSpacing: '-2%',
          background: '#AA915D',
          borderRadius: '16px',
          _hover: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #443A25 120.22%)',
          },
          _active: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #241A15 120.22%)',
          },
          _disabled: {
            opacity: 1,
            background:
              'linear-gradient(101.56deg, rgba(170, 145, 93, 0) 19.94%, rgba(68, 58, 37, 0) 120.22%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
            _hover: {
              background:
                'linear-gradient(101.56deg, rgba(170, 145, 93, 0) 19.94%, rgba(68, 58, 37, 0) 120.22%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important',
            },
          },
        },
        h2outlined: {
          color: '#AA915D',
          fontWeight: '500',
          fontSize: { base: '16px', md: '14px' },
          lineHeight: { base: '16px', md: '14px' },
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          background: 'transparent',
          border: '1.5px solid #AA915D',
          borderRadius: '16px',
          transition: 'all 0.5s linear',
          _hover: {
            color: '#443A25',
            borderColor: '#443A25',
          },
          _active: {
            color: '#241A15',
            borderColor: '#241A15',
          },
          _disabled: {
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.2)',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            _hover: {
              color: 'rgba(0, 0, 0, 0.2)',
              borderColor: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        h3: {
          background: '#465D55',
          borderRadius: '16px',
          color: '#FFFFFF',
          width: '165.2px',
          height: '50px',
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '-2%',
          textAlign: 'center',
          transition: 'all 0.5s linear',
          _hover: {
            background: 'rgba(70, 93, 85, 1)',
          },
          _active: {
            background: 'rgba(70, 93, 85, 1)',
          },
          _disabled: {
            opacity: 1,
            background: '#00000066',
            _hover: {
              background: '#00000066 !important',
            },
          },
        },
        h3outlinedRed: {
          width: '138px',
          height: '34px',
          borderRadius: '10px',
          opacity: '0px',
          border: '1px solid rgba(227, 26, 26, 1)',
          color: 'rgba(227, 26, 26, 1)',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 1,
            border: '1px solid rgba(227, 26, 26, 0.5)', // added 50% for testing
            _hover: {
              border: '1px solid rgba(227, 26, 26, 0.5)',
            },
          },
        },
        h3outlinedYellow: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid rgba(241, 191, 120, 1)',
          color: 'rgba(241, 191, 120, 1)',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 1,
            border: '1px solid rgba(241, 191, 120, 0.5)', // added 50% for testing
            _hover: {
              border: '1px solid rgba(241, 191, 120, 0.5)',
            },
          },
        },
        h3outlinedBlue: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #ADD8E6', // addded light blue color
          color: '#ADD8E6',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #ADD8E6', // added 50% for testing
            _hover: {
              border: '1px solid #ADD8E6',
            },
          },
        },
        h3outlinedWhiteGreen: {
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #93C3B3', // addded light blue color
          color: '#93C3B3',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #93C3B3', // added 50% for testing
            _hover: {
              border: '1px solid #93C3B3',
            },
          },
        },
        h3outlinedGreen: {
          borderRadius: '16px',
          border: '1px solid #AA915D', // addded light blue color
          color: '#AA915D',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          height: { base: '50px', md: '44px' },
          _hover: {},
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #AA915D', // added 50% for testing
            _hover: {
              border: '1px solid #AA915D',
            },
          },
        },
        h3outlined: {
          width: '91px',
          height: '34px',
          borderRadius: '10px',
          border: '1px solid #E9EDF7',
          color: '#00000099',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
          textAlign: 'center',
          _hover: {
            border: '1px solid #00000099',
          },
          _active: {},
          _disabled: {
            opacity: 0.5,
            border: '1px solid #00000099', // added 50% for testing
            _hover: {
              border: '1px solid #00000099',
            },
          },
        },
        h4: {
          widht: '150px',
          height: '21px',
          bg: 'transparent',
          color: '#AA915D',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '22px',
          letterSpacing: '-3%',
          textAlign: 'center',
          _hover: {
            background:
              'linear-gradient(101.56deg, #AA915D 19.94%, #443A25 120.22%)', // use gradient from h1cta: _hover
            '-webkit-background-clip': 'text',
            'background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
          },
          _active: {},
          _disabled: {
            opacity: 1,
            color: 'rgba(0, 0, 0, 0.2)',
            _hover: {
              color: 'rgba(0, 0, 0, 0.2)',
            },
          },
        },
        h1BadgeBlue: {
          bg: '#93C3B3',
          color: '#FFFFFF',
          borderRadius: '16px',
          w: '310px',
          h: '50px',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '32px',
          letterSpacing: '-2%',
          transition: 'all 0.5s linear',
          _hover: {
            bg: '#769c8f',
          },
          _active: {
            bg: '#465D55',
          },
          _disabled: {
            opacity: 0.4,
            bg: '#93C3B3',
            _hover: {
              bg: '#93C3B3 !important',
            },
          },
        },
        google: () => ({
          transition: 'all 0.3s linear',
          borderRadius: '16px',
          height: { base: '50px', md: '44px' },
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: '-2%',
          color: '#2B3674',
          bg: '#FFFFFF',
          _focus: {},
          _active: {},
          _hover: {},
        }),
        outlineTag: {
          bg: 'transparent',
          fontWeight: '700',
          fontSize: '8.99px',
          lineHeight: '16.47px',
          letterSpacing: '-2%',
          color: '#00000066',
          border: '0.9px solid',
          borderColor: '#0000004D',
          borderRadius: '8.99px',
          overflow: 'hidden',
          p: '2.06px 9.61px',
          height: 'fit-content',
        },
        accept: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-2%',
          height: '30px',
          bg: '#05CD991A',
          borderRadius: '10px',
          color: '#01B574',
          _hover: {
            bg: 'green.400',
            color: 'white',
          },
        },
        decline: {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '-2%',
          height: '30px',
          bg: '#EE5D501A',
          color: 'horizonRed.500',
          borderRadius: '10px',
          _hover: {
            bg: 'red.400',
            color: 'white',
          },
        },
        underlineRed: (props: any) => ({
          bg: 'transparent',
          overflow: 'hidden',
          textDecoration: 'underline',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '-2%',
          fontWeight: '400',
          transition: 'all .25s ease',
          color: '#FF4D4F',
          _hover: {},
        }),
        badgeTagBlue: {
          bg: '#93C3B3',
          borderRadius: '10px',
          h: '50px',
          color: '#00000099',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '-2%',
        },
        // using for input date field
        date: () => ({
          bg: mode('transparent', 'navy.800'),
          border: '1px solid',
          color: 'rgba(0, 0, 0, 0.8)',
          borderColor: 'general.100',
          borderRadius: '16px',
          fontSize: '14px',
          fontWeight: '500',
          maxH: '50px',
          p: '24px',
        }),
      },
    },
  },
};
