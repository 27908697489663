import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { closeAddPhotoDialog } from 'contexts/redux/dialog/dialogsSlice';
import {
  createMediaItem,
  updateMediaItem,
} from 'contexts/redux/mediaItem/mediaItemSlice';
import { RootState } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClear, MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { MediaItem } from 'services/@types';
interface AddPhotoModalProps {}
export const AddPhotoModal: React.FC<AddPhotoModalProps> = () => {
  const { onClose } = useDisclosure();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addPhotoDialog,
  );
  const dispatch = useDispatch<any>();
  const [tagInputValue, setTagInputValue] = React.useState('');
  const [mediaItemProps, setMediaItemProps] = React.useState<
    Partial<MediaItem>
  >({});

  const isUpdating = useMemo(() => !!mediaItemProps?.id, [mediaItemProps?.id]);

  const handleAddTag = useCallback(
    (tag: string) => {
      if (mediaItemProps.tags?.includes(tag)) return;
      if (mediaItemProps.tags?.length >= 30) return;
      setMediaItemProps({
        ...mediaItemProps,
        tags: [...(mediaItemProps?.tags || []), tag],
      });
      setTagInputValue('');
    },
    [mediaItemProps],
  );
  const handleDeleteTag = useCallback(
    (tag: string) => {
      setMediaItemProps({
        ...mediaItemProps,
        tags: mediaItemProps.tags.filter((t) => t !== tag),
      });
    },
    [mediaItemProps],
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setMediaItemProps((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSaveClicked = useCallback(() => {
    if (!mediaItemProps.id) {
      dispatch(createMediaItem(mediaItemProps as MediaItem));
      dispatch(closeAddPhotoDialog());
      return;
    }
    dispatch(
      updateMediaItem({
        mediaItemId: mediaItemProps.id,
        updates: mediaItemProps as MediaItem,
      }),
    );
    dispatch(closeAddPhotoDialog());
  }, [dispatch, mediaItemProps]);

  useEffect(() => {
    setMediaItemProps(dialog?.item || {});
  }, [dialog?.item]);

  const handleCancelClicked = useCallback(() => {
    dispatch(closeAddPhotoDialog());
  }, [dispatch]);
  const tagsVariants = {
    pop: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        // stiffness: 260,
        // damping: 20,
        duration: 0.2,
      },
    },
  };
  return (
    <Modal isOpen={!!dialog?.item} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        ps="32px"
        pe="22px"
        pb="20px"
        pt="36px"
        borderRadius="3xl"
        mx={1}
        maxW={'540px'}>
        <ModalHeader p={0}>
          <HStack w="100%">
            <Text>
              {isUpdating
                ? t('gallery.dialogs.upload_photo.title_update')
                : t('gallery.dialogs.upload_photo.title')}
            </Text>
            <Spacer />
            <Button variant="h1cta" onClick={handleSaveClicked}>
              {t('gallery.dialogs.upload_photo.save')}
            </Button>
            <ModalCloseButton
              top={0}
              right={0}
              position={'relative'}
              onClick={handleCancelClicked}
            />
          </HStack>
        </ModalHeader>
        <Flex w="100%">
          <VStack align="start" gap={4} w="100%">
            <Text fontSize={'sm'} maxW={230}>
              {t('gallery.dialogs.upload_photo.description')}
            </Text>
            <HStack align="start" wrap="wrap" gap={6} w="100%">
              <AuthImage
                w={160}
                h={160}
                minW={160}
                size="md"
                borderRadius="3xl"
                path={mediaItemProps?.media?.[0]?.url}
              />
              <VStack alignSelf={'start'} w="100%" maxW={290}>
                <VStack align="start" justify={'start'} w="100%" maxW={290}>
                  <InputField
                    name="name"
                    value={mediaItemProps.name}
                    w="100%"
                    maxLength={120}
                    onChange={handleChange}
                    placeholder={t('gallery.new_album.placeholder.add_title')}
                  />
                  <InputField
                    name="alternativeTitle"
                    value={mediaItemProps.alternativeTitle}
                    w="100%"
                    maxLength={120}
                    tooltipLabel={t('gallery.alternative_text_tooltip')}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_alternative_text',
                    )}
                  />
                  <TextField
                    name="description"
                    value={mediaItemProps.description}
                    w="100%"
                    tooltipLabel={t('gallery.description_text_tooltip')}
                    minH={'100px'}
                    maxLength={500}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_description',
                    )}
                  />

                  <InputField
                    value={tagInputValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && tagInputValue) {
                        e.preventDefault();
                        handleAddTag(tagInputValue);
                      }
                    }}
                    w="100%"
                    maxLength={50}
                    onChange={(e) => setTagInputValue(e.target.value)}
                    placeholder={t('gallery.dialogs.upload_photo.add_tags')}
                    onAdd={handleAddTag}
                  />
                  <HStack wrap="wrap" gap={1} spacing={1} maxW={280}>
                    <AnimatePresence>
                      {mediaItemProps?.tags?.map((tag) => (
                        <Button
                          as={motion.div}
                          variant={'outlineTag'}
                          key={tag}
                          animate={'pop'}
                          variants={tagsVariants}
                          initial={{ scale: 0.6, opacity: 0 }}
                          exit={{ scale: 0.6, opacity: 0 }}
                          transition="0.1s"
                          maxW={280}
                          display={'flex'}
                          rightIcon={
                            <Icon
                              as={MdClear}
                              fontSize="md"
                              onClick={() => {
                                handleDeleteTag(tag);
                              }}
                            />
                          }>
                          <Text
                            textAlign={'start'}
                            whiteSpace="normal"
                            maxW={220}
                            wordBreak="break-word">
                            {tag}
                          </Text>
                        </Button>
                      ))}
                    </AnimatePresence>
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          </VStack>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
