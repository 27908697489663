import { Badge, HStack, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
const eventTypes = [
  'wedding',
  'birthday',
  'corporate',
  'social',
  'conference',
  'exhibition',
  'trade_show',
  'festival',
  'concert',
  'party',
  'workshop',
  'seminar',
  'webinar',
  'meeting',
  'networking',
  'training',
  'gala_dinner',
  'award_ceremony',
  'product_launch',
  'press_conference',
  'fashion_show',
  'film_screening',
  'art_exhibition',
  'charity',
  'fundraiser',
  'sports',
  'religious',
  'community',
  'other',
];
interface CreateEventHeaderProps {
  handleChange: (key: string, value: any) => void;
  handleCreateEvent: () => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventHeader(props: CreateEventHeaderProps) {
  const { handleChange, handleCreateEvent, event, errorList } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentEventType = useMemo(() => event?.type || '', [event?.type]);
  const selectedEventType = useMemo(
    () => (currentEventType ? [`event_types.${currentEventType}`] : []),
    [currentEventType],
  );
  const handleChangeEventType = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_types.', '');
      if (selectedEventType.includes(selected)) {
        handleChange('type', '');
        return;
      }
      handleChange('type', formattedSelected);
    },
    [selectedEventType, handleChange],
  );
  return (
    <Stack>
      <HStack justify="space-between">
        <HStack
          flexDirection={{ base: 'column', md: 'row' }}
          align={{ base: 'start', md: 'center' }}>
          <Text variant="cardTitle">{t('create_event.title')}</Text>
          <Badge bg="tag.new" variant="tag">
            {t('create_event.tags.new')}
          </Badge>
        </HStack>
        <VStack>
          <HStack>
            <Icon
              as={FiX}
              w="16px"
              h="16px"
              onClick={() => navigate(-1)}
              cursor="pointer"
            />
          </HStack>
        </VStack>
      </HStack>
      <HStack align="end" wrap="wrap" gap="40px">
        <DropdownField
          options={eventTypes.map((type) => `event_types.${type}`)}
          onSelected={handleChangeEventType}
          selectedOptions={selectedEventType}
          placeholder={t('create_event.placeholder.select_event_type')}
          w={{ base: '100%', lg: '318px' }}
          menuButton={{
            borderColor: errorList.includes('type') ? 'error.100' : 'gray.200',
          }}
        />
        <InputField
          label={t('create_event.label.event_title')}
          placeholder={t('create_event.placeholder.override_title_if_needed')}
          w={{ base: '100%', lg: '318px' }}
          name="name"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
          value={event?.name || ''}
        />
      </HStack>
    </Stack>
  );
}
