import { Flex, Icon, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import InputDateField from 'components/fields/InputDateField';
import LocationAutocomplete from 'components/locationAutocomplete';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
interface CreateEventDetailsProps {
  handleChange: (key: string, value: any) => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventDetails(props: CreateEventDetailsProps) {
  const { handleChange, event, errorList } = props;
  const { t } = useTranslation();
  const dateAndTime = useMemo(() => event?.dateAndTime, [event?.dateAndTime]);
  const handleChangeDateAndTime = useCallback(
    (name: string, value: string) => {
      let newDateAndTime = dateAndTime;
      if (!newDateAndTime) {
        const newDate = new Date();
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        newDateAndTime = newDate;
      }
      if (name === 'date') {
        newDateAndTime.setFullYear(new Date(value).getFullYear());
        newDateAndTime.setMonth(new Date(value).getMonth());
        newDateAndTime.setDate(new Date(value).getDate());
      }
      if (name === 'time') {
        newDateAndTime.setHours(Number(value.split(':')[0]));
        newDateAndTime.setMinutes(Number(value.split(':')[1]));
      }
      handleChange('dateAndTime', newDateAndTime);
    },
    [dateAndTime, handleChange],
  );
  return (
    <Stack gap={3}>
      <Text variant="eventSubTitle">{t('create_event.event_details')}</Text>
      <Flex wrap="wrap" gap="40px" alignItems="end">
        <LocationAutocomplete
          label={t('create_event.placeholder.location')}
          value={event?.location || ''}
          onChange={(e: any) => handleChange('location', e)}
          placeholder={t('create_event.placeholder.location')}
          controlStyles={{
            borderColor: errorList.includes('location') ? '#EE5D50' : '#E0E5F2',
          }}
          isIconHidden
        />
        <Flex gap={1} w={{ base: '100%', lg: '318px' }}>
          {/* <InputField
            label={t('create_event.label.event_date')}
            placeholder={t('create_event.placeholder.event_date')}
            defaultValue={null}
            type="date"
            flex="1"
            name="date"
            onChange={(e: any) =>
              handleChangeDateAndTime(e.target.name, e.target.value)
            }
            value={dateAndTime?.toISOString().slice(0, 10)}
            borderColor={
              errorList.includes('dateAndTime') ? 'red.500' : 'gray.300'
            }
          /> */}
          <InputDateField
            label={t('create_event.label.event_date')}
            value={dateAndTime || new Date()}
            onChange={(e: Date) => {
              handleChangeDateAndTime('dateAndTime', e.toString());
            }}
            justifyContent="end"
            w="100%"
            buttonStyles={{
              w: { base: '220px', md: 'fit-content' },
              maxW: { base: '220px', md: 'fit-content' },
            }}
          />
          <InputField
            label={t('create_event.label.time')}
            placeholder={t('create_event.placeholder.time')}
            type="time"
            defaultValue={null}
            flex="1"
            name="time"
            onChange={(e: any) =>
              handleChangeDateAndTime(e.target.name, e.target.value)
            }
            value={dateAndTime?.toLocaleTimeString().slice(0, 5)}
            borderColor={
              errorList.includes('dateAndTime') ? 'red.500' : 'gray.300'
            }
          />
        </Flex>
        <InputField
          label={t('create_event.label.number_of_guests')}
          placeholder={t('create_event.placeholder.number_of_guests')}
          w={{ base: '100%', lg: '174px' }}
          flex="1"
          type="number"
          name="numberOfGuests"
          onChange={(e: any) => handleChange(e.target.name, e.target.value)}
          value={event?.numberOfGuests}
          zIndex="1"
          iconStyle={{ justifyContent: 'start' }}
          iconRight={
            <Tooltip
              label={t('create_event.toltip_label', {
                profit: 100,
                cost: 500000,
              })}
              zIndex="1"
              color="general.100"
              variant="event">
              <IconButton
                minW="20px"
                aria-label=""
                variant="unstyled"
                color="#93C3B3"
                icon={
                  <Icon
                    as={AiOutlineInfoCircle}
                    w="20px"
                    h="20px"
                    verticalAlign="sub"
                  />
                }
              />
            </Tooltip>
          }
          iconPointerEvents="all"
        />
      </Flex>
    </Stack>
  );
}
