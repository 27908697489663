// Chakra imports
import {
  Button,
  Flex,
  Heading,
  Image,
  Show,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
// Assets
import Background from 'assets/img/auth/get_started.jpeg';
import DefaultAuth from 'layouts/auth/variants/Default';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/svg/brilliant-logo.svg';
import { useSeatInvite } from './hooks/useSeatInvite';

function InviteSeat() {
  const navigate = useNavigate();
  // Chakra color mode
  const { business, senderUser } = useSeatInvite();
  const { t } = useTranslation();
  return (
    <DefaultAuth image={Background}>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        justifyContent="center"
        flexDirection="column"
        gap={10}
        align="center"
        // px={{ base: '20px', sm: '20px', md: '60px', lg: '120px', xl: '150px' }}
      >
        <Spacer />
        <VStack gap={2} align="flex-start" flex={1} maxW={320} justify="end">
          <Show above="md">
            <Image src={Logo} mb={20} alt="logo" w="149px" />
          </Show>
          <Heading>
            {senderUser?.firstName} has invited you to join{' '}
            {business.businessName} on Brilliant. Finally a system that has it
            all
          </Heading>
          <Text color="primary.100" fontWeight="400" fontSize="md">
            {
              'A real-time event planning app, where you can communicate and stay ahead with sharing details or receive change requests, create templates for unlimited events, ensure professional planning for events of all sizes, and stay organized with quotes, contracts and expenses.'
            }
          </Text>
        </VStack>
        <Spacer />
        <VStack w="100%" flex={1} maxW={330} align="start" justify={'end'}>
          <Button
            variant="h1cta"
            onClick={() => navigate('/auth/sign-up')}
            w="100%">
            {t('get_started.sign_up_with_email')}
          </Button>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px">
            <Text color="primary.100" fontWeight="400" fontSize="14px">
              {t('get_started.already_a_sign_up')}
              <NavLink to="/auth/sign-in">
                <Text
                  color="#aa915d"
                  as="span"
                  ms="5px"
                  fontWeight="500"
                  textDecoration="underline">
                  {t('get_started.log_in')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </VStack>
        <Spacer />
      </Flex>
    </DefaultAuth>
  );
}

export default InviteSeat;
