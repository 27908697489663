import { useTranslation } from 'react-i18next';
import { Button, Flex, HStack, Icon, Text, Image } from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import Card from 'components/card/Card';
import { FiPlus, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import EventCard from 'components/eventsDialog/eventCard';
import TextField from 'components/fields/TextField';
import InputDateField from 'components/fields/InputDateField';
import useCreateTask from './hooks/useCreateTask';
import DropdownField from 'components/fields/DropdownField';
import { useCallback, useMemo } from 'react';
import { IoIosAlert } from 'react-icons/io';
import ImportantFlag from '../../../../assets/svg/priority/important-flag.svg';
import ChooseEventBtn from 'components/buttons/ChooseEventBtn';
const priorities = ['none', 'important'];
export default function CreateTask() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    task,
    event,
    handleChangeTask,
    handleCreateTask,
    handleOpenEventDialog,
    errorList,
  } = useCreateTask();
  const currentEventRole = useMemo(
    () => task?.priority || '',
    [task?.priority],
  );
  const selectedEventRole = useMemo(
    () => (currentEventRole ? [`task_priority.${currentEventRole}`] : []),
    [currentEventRole],
  );
  const handleChangeEventRole = useCallback(
    (selected: { label: string }) => {
      const { label } = selected;
      const formattedSelected = label.replace('task_priority.', '');
      const event: { target: { name: string; value?: string } } = {
        target: {
          name: 'priority',
        },
      };
      if (selectedEventRole.includes(label)) {
        event.target.value = '';
        handleChangeTask(event);
        return;
      }
      event.target.value = formattedSelected;
      handleChangeTask(event);
    },
    [selectedEventRole, handleChangeTask],
  );
  return (
    <Flex
      w="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      pb={{ base: '100px', md: '0' }}
      flexDirection="column"
      gap={4}>
      <Card gap={5} maxW="6xl">
        <HStack justifyContent="space-between" align="center">
          <Text variant="cardTitle">{t('create_task.title')}</Text>
          <Icon
            as={FiX}
            w="16px"
            h="16px"
            cursor="pointer"
            onClick={() => navigate(-1)}
          />
        </HStack>
        <Flex wrap="wrap" gap={6} alignItems="end">
          <InputField
            label={t('create_task.task_name')}
            placeholder={t('create_task.task_name')}
            w={{ base: '100%', lg: '318px' }}
            name="name"
            value={task?.name || ''}
            onChange={handleChangeTask}
            borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
          />
          <Flex
            w="100%"
            maxW={{ base: '100%', md: '420px' }}
            wrap="wrap"
            gap={2}
            alignItems="end">
            <InputDateField
              label={t('create_event.label.event_date')}
              value={task?.dateAndTime || new Date()}
              onChange={(e: Date) => {
                const event = {
                  target: {
                    value: e.toString(),
                    name: 'dateAndTime',
                  },
                };
                handleChangeTask(event);
              }}
              justifyContent="end"
              w="100%"
              buttonStyles={{
                w: { base: '220px', md: 'fit-content' },
                maxW: { base: '220px', md: 'fit-content' },
                h: { base: '50px', md: '50px' },
              }}
            />
            <DropdownField
              options={priorities.map((role, key) => ({
                label: `task_priority.${role}`,
                icon: 'important' === role && <Image src={ImportantFlag} />,
                id: key,
              }))}
              onSelected={handleChangeEventRole}
              selectedOptions={selectedEventRole}
              placeholder={
                task?.priority ? (
                  task?.priority === 'important' ? (
                    <Image src={ImportantFlag} />
                  ) : (
                    t(`task_priority.${task.priority}`)
                  )
                ) : (
                  t('create_task.priority')
                )
              }
              label={t('create_task.priority')}
              w="100%"
              maxW={{ base: '100%', md: '140px' }}
              flex="1"
              zIndex={1}
              formLabelStyles={{ mb: 1 }}
              menuButton={{
                borderColor: 'general.100',
                h: '50px',
                borderRadius: '16px',
              }}
            />
          </Flex>
          {event && (
            <EventCard
              event={event}
              onClick={handleOpenEventDialog}
              isQuoteEvent
              flex="1"
            />
          )}
          {!event && (
            <ChooseEventBtn
              w={{ base: '100%', md: 'fit-content' }}
              onClick={handleOpenEventDialog}
              maxW={{ base: '100%', md: '320px' }}
              flex="1"
              px={24}
            />
          )}
          <TextField
            label={t('create_task.description')}
            placeholder={t('create_task.description')}
            w="100%"
            minH="150px"
            resize="none"
            name="description"
            value={task?.description || ''}
            onChange={handleChangeTask}
          />
          <InputField
            label={t('create_task.assign_task')}
            placeholder={t('create_task.assign_task')}
            w="100%"
            name="guests"
            value={task?.guests || ''}
            onChange={handleChangeTask}
          />
          <HStack
            justifyContent="end"
            w="100%"
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            gap={2}>
            {errorList.length > 0 && (
              <HStack justify="center">
                <Icon
                  as={IoIosAlert}
                  w="16px"
                  h="16px"
                  bg="white"
                  color="error.100"
                  borderRadius="50%"
                />
                <Text variant="errorTitle">{t('create_event.error')}</Text>
              </HStack>
            )}
            <Button
              variant="h1cta"
              minH={{ base: '50px', md: 'fit-content' }}
              borderRadius="16px"
              w={{ base: '100%', md: '140px' }}
              onClick={handleCreateTask}>
              {t('create_task.create_task')}
            </Button>
          </HStack>
        </Flex>
      </Card>
    </Flex>
  );
}
