import {
  Button,
  ButtonProps,
  CircularProgress,
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  forwardRef,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import CalendarIcon from 'assets/svg/date/calendar.svg';
import { getDate } from 'date-fns';
import { he } from 'date-fns/locale/he';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/css/InputDateField.css';
registerLocale('he', he);

export interface InputDateFieldProps {
  id?: string;
  label?: string;
  extra?: JSX.Element | string;
  errorLabel?: string;
  value?: Date;
  containerStyles?: FlexProps;
  buttonStyles?: ButtonProps;
  labelProps?: FormLabelProps;
  [x: string]: any;
}
export default function Default(props: InputDateFieldProps) {
  const {
    id,
    label,
    errorLabel,
    extra,
    value,
    isLoading,
    w,
    mb,
    type,
    labelProps,
    buttonStyles,
    containerStyles,
    ...rest
  } = props;
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const { t, i18n } = useTranslation();

  const CustomInput = forwardRef(({ value, onClick, className }, ref) => {
    return (
      <Flex direction="column" mb={mb} w={w} {...containerStyles}>
        <HStack align="center">
          {isLoading && (
            <CircularProgress isIndeterminate color="brand.900" size="14px" />
          )}
          <FormLabel
            display={label || extra ? 'flex' : 'none'}
            htmlFor={id}
            mb={1}
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="medium"
            _hover={{ cursor: 'pointer' }}
            {...labelProps}>
            {label}
            <Text fontSize="sm" fontWeight="400" ms="2px">
              {extra}
            </Text>
          </FormLabel>
        </HStack>
        <Button
          onClick={onClick}
          className={className}
          ref={ref}
          rightIcon={
            <Image
              src={CalendarIcon}
              ms="14px"
              w="32px"
              h="32px"
              bg="#93C3B3"
              padding="5px"
              borderRadius="10px"
            />
          }
          variant="date"
          {...buttonStyles}>
          {value}
        </Button>
        {errorLabel && (
          <Text fontSize="xs" fontWeight={'semibold'} color="red.500" mt="2px">
            {t(errorLabel)}
          </Text>
        )}
      </Flex>
    );
  });

  const renderDayContents = (day: any) => {
    const isDayTaken = value && getDate(value) === day;
    return (
      <Text
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="12px"
        borderRadius="10px"
        bg={isDayTaken ? '#AA915D' : 'white'}
        color={isDayTaken ? 'white' : 'rgba(0, 0, 0, 0.8)'}
        _hover={{ bg: '#AA915D', color: 'white' }}>
        {day}
      </Text>
    );
  };

  const calendarContainer = ({ className, children }: any) => {
    return (
      <CalendarContainer className={`${className} input-date-field`}>
        {children}
      </CalendarContainer>
    );
  };

  return (
    <DatePicker
      customInput={<CustomInput />}
      renderDayContents={renderDayContents}
      calendarContainer={calendarContainer}
      showPopperArrow={false}
      dateFormat="MMM dd, yyyy"
      popperPlacement="bottom-start"
      selected={value}
      locale={i18n.language === 'he' ? 'he' : 'en'}
      {...rest}
    />
  );
}
