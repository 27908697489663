import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Dropzone from 'components/dropzone';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import {
  createAlbum,
  deleteAlbum,
  updateAlbum,
} from 'contexts/redux/albums/albumSlice';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  closeAddAlbumDialog,
  closeAlertDialog,
  openAlertDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClear, MdInfoOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Album, UrlItem } from 'services/@types';
interface AddAlbumModalProps {}
export const AddAlbumModal: React.FC<AddAlbumModalProps> = () => {
  const { onClose } = useDisclosure();
  const { t } = useTranslation();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addAlbumDialog,
  );
  const user = useMyUser();
  const dispatch = useDispatch<any>();
  const [albumProps, setAlbumProps] = useState<Partial<Album>>({});
  const [tagInputValue, setTagInputValue] = React.useState('');
  const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');

  const handleDeleteTag = useCallback((tag: string) => {
    setAlbumProps((prev) => ({
      ...prev,
      tags: prev.tags?.filter((t) => t !== tag),
    }));
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setNameErrorMessage('');
    setAlbumProps((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleAddTag = useCallback(
    (tag: string) => {
      if (albumProps.tags?.includes(tag)) return;
      if (albumProps.tags?.length >= 30) return;
      setAlbumProps((prev) => ({
        ...prev,
        tags: [...(prev.tags || []), tag],
      }));
      setTagInputValue('');
    },
    [albumProps.tags],
  );

  const handleSaveClicked = useCallback(() => {
    if (!albumProps.name) {
      setNameErrorMessage(t('gallery.new_album.error.name_is_required'));
      return;
    }
    if (dialog?.item?.id) {
      dispatch(
        updateAlbum({
          albumId: dialog.item.id,
          updates: { ...albumProps } as Album,
        }),
      );
      return;
    }
    dispatch(
      createAlbum({
        ...albumProps,
        targetId: user.id,
        target: 'user',
      } as Album),
    );
    dispatch(closeAddAlbumDialog());
  }, [albumProps, dialog?.item?.id, dispatch, user?.id, t]);

  useEffect(() => {
    setAlbumProps(dialog?.item || {});
  }, [dialog?.item]);

  const handleCancelClicked = useCallback(() => {
    dispatch(closeAddAlbumDialog());
  }, [dispatch]);

  const handleDeleteClicked = useCallback(() => {
    if (dialog?.item?.id) {
      dispatch(
        openAlertDialog({
          title: t('gallery.new_album.delete.title'),
          content: t('gallery.new_album.delete.description'),
          onConfirm: () => {
            dispatch(deleteAlbum({ albumId: dialog.item.id }));
            dispatch(closeAddAlbumDialog());
          },
          onClose: () => dispatch(closeAlertDialog()),
        }),
      );
    }
  }, [dialog?.item?.id, dispatch, t]);
  const tagsVariants = {
    pop: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        // stiffness: 260,
        // damping: 20,
        duration: 0.2,
      },
    },
  };
  return (
    <Modal isOpen={!!dialog?.item} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        ps="32px"
        pt="36px"
        pb="20px"
        maxW="540px"
        borderRadius="3xl"
        mx={2}
        minH={520}
        gap={6}>
        <ModalHeader p={0}>
          <HStack w="100%">
            <Text fontSize="2xl">
              {albumProps.id
                ? t('gallery.new_album.edit_title')
                : t('gallery.new_album.title')}
            </Text>
            <Spacer />
            <Button
              variant="underlineRed"
              onClick={handleDeleteClicked}
              px={6}
              display={dialog?.item?.id ? 'flex' : 'none'}>
              {t('gallery.new_album.delete.title')}
            </Button>
            <Button variant="h1cta" onClick={handleSaveClicked} px={14}>
              {t('gallery.dialogs.upload_photo.save')}
            </Button>
            <ModalCloseButton
              top={0}
              right={0}
              position={'relative'}
              onClick={handleCancelClicked}
            />
          </HStack>
        </ModalHeader>
        <Flex>
          <VStack align="start" gap={8} w="100%">
            <HStack align="start" wrap="wrap" gap={4} w="100%">
              <Flex h={160} w={160} flexDirection="column" gap={4}>
                <Dropzone
                  currentImage={albumProps.media?.[0]?.url}
                  h={160}
                  w={160}
                  flex={1}
                  key={`upload`}
                  uploadBarStyle="line"
                  target={albumProps?.id ? 'album' : 'user'}
                  targetId={albumProps?.id || user?.id}
                  access="public"
                  uploadKey={`thumbnailImage`}
                  onUploadCompleted={(data: UrlItem) => {
                    handleChange({
                      target: {
                        name: 'media',
                        value: [data],
                      },
                    } as any);
                  }}
                  dropzoneOptions={{
                    accept: 'image/*,video/*',
                    multiple: false,
                  }}
                />
                <Text fontSize={'sm'} maxW={230}>
                  {t('gallery.dialogs.upload_photo.description')}
                </Text>
              </Flex>

              <VStack alignSelf={'start'} flex={1} align="start">
                <VStack align="start" justify={'start'} w="100%" maxW={290}>
                  <InputField
                    name="name"
                    value={albumProps.name}
                    w="100%"
                    maxLength={120}
                    onChange={handleChange}
                    placeholder={t('gallery.new_album.placeholder.add_title')}
                    errorLabel={nameErrorMessage}
                  />

                  <InputField
                    name="subject"
                    value={albumProps.subject}
                    w="100%"
                    tooltipLabel={t('gallery.alternative_text_tooltip')}
                    maxLength={120}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_alt_text',
                    )}
                  />

                  <TextField
                    name="description"
                    value={albumProps.description}
                    w="100%"
                    tooltipLabel={t('gallery.description_text_tooltip')}
                    maxLength={500}
                    onChange={handleChange}
                    placeholder={t(
                      'gallery.new_album.placeholder.add_description',
                    )}
                  />

                  <InputField
                    value={tagInputValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && tagInputValue) {
                        e.preventDefault();
                        handleAddTag(tagInputValue);
                      }
                    }}
                    w="100%"
                    maxLength={50}
                    onChange={(e) => setTagInputValue(e.target.value)}
                    placeholder={t('gallery.dialogs.upload_photo.add_tags')}
                    onAdd={(tag: any) => {
                      handleAddTag(tag);
                    }}
                  />
                  <HStack wrap="wrap" gap={'6px'} spacing={0} maxW={280}>
                    <AnimatePresence>
                      {albumProps.tags?.map((tag) => (
                        <Button
                          as={motion.div}
                          variant={'outlineTag'}
                          key={tag}
                          animate={'pop'}
                          variants={tagsVariants}
                          initial={{ scale: 0.6, opacity: 0 }}
                          exit={{ scale: 0.6, opacity: 0 }}
                          transition="0.1s"
                          maxW={280}
                          display={'flex'}
                          rightIcon={
                            <Icon
                              as={MdClear}
                              fontSize="xs"
                              onClick={() => {
                                handleDeleteTag(tag);
                              }}
                            />
                          }>
                          <Text
                            textAlign={'start'}
                            whiteSpace="normal"
                            maxW={220}
                            fontSize="10px"
                            wordBreak="break-word">
                            {tag}
                          </Text>
                        </Button>
                      ))}
                    </AnimatePresence>
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          </VStack>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
