import {
  Flex,
  FormLabel,
  FormLabelProps,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import SeachIcon from 'assets/img/seach/search.png';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
const GMApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
interface LocationAutocompleteProps {
  value: any;
  onChange: (value: any) => void;
  inputStyles?: any;
  optionStyles?: any;
  singleValueStyles?: any;
  language?: string;
  containerStyles?: any;
  controlStyles?: any;
  placeholderStyles?: any;
  placeholder?: string;
  isIconHidden?: boolean;
  mb?: string;
  w?: string;
  label?: string;
  labelProps?: FormLabelProps;
}
export default function LocationAutocomplete(props: LocationAutocompleteProps) {
  const {
    value,
    onChange,
    inputStyles,
    optionStyles,
    singleValueStyles,
    language,
    containerStyles,
    controlStyles,
    placeholderStyles,
    placeholder,
    isIconHidden = false,
    mb,
    w,
    label,
    labelProps,
  } = props;
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  return (
    <Flex direction="column" mb={mb} w={w}>
      <HStack align="center">
        <FormLabel
          display={label ? 'flex' : 'none'}
          mb={1}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="medium"
          _hover={{ cursor: 'pointer' }}
          {...labelProps}>
          {label}
        </FormLabel>
      </HStack>
      <GooglePlacesAutocomplete
        apiKey={GMApiKey}
        apiOptions={{ language: language || 'en' }}
        selectProps={{
          value: value,
          placeholder: placeholder,
          onChange,
          styles: {
            input: (provided) => {
              return {
                ...provided,
                ...inputStyles,
              };
            },
            option: (provided) => {
              return {
                ...provided,
                ...optionStyles,
              };
            },
            singleValue: (provided) => {
              return {
                ...provided,
                ...singleValueStyles,
              };
            },
            container: (provided) => {
              return {
                ...provided,
                width: '100%',
                zIndex: 10,
                '@media (min-width: 960px)': {
                  width: '318px',
                },
                ...containerStyles,
              };
            },
            menu: (provided) => {
              return {
                ...provided,
                borderRadius: '16px',
                zIndex: 10,
              };
            },
            menuList: (provided) => {
              return {
                ...provided,
                borderRadius: '16px',
                zIndex: 10,
              };
            },
            control: (provided) => ({
              ...provided,
              height: '50px',
              borderRadius: '16px',
              borderColor: '#E0E5F2',
              boxShadow: 'none',
              ':hover': {
                borderColor: '#E0E5F2 !important',
                boxShadow: 'none',
              },
              ':active': {
                borderColor: '#E0E5F2',
                boxShadow: 'none',
              },
              ':focus': {
                borderColor: '#E0E5F2',
                boxShadow: 'none',
              },
              ...controlStyles,
            }),
            placeholder: (provided) => {
              return {
                ...provided,
                color: '#E0E5F2',
                ...placeholderStyles,
              };
            },
            indicatorSeparator: () => ({
              display: 'none',
            }),
            indicatorsContainer: (base, props) => {
              return {
                ...base,
                position: 'relative',
                ':after': isIconHidden
                  ? { display: 'none' }
                  : {
                      // create search icon
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-100%, -50%)',
                      width: '20px',
                      height: '20px',
                      background: `url(${SeachIcon}) no-repeat`,
                      backgroundSize: 'contain',
                      cursor: 'pointer',
                    },
                // remove default arrow
                '.css-tj5bde-Svg': {
                  display: 'none',
                },
              };
            },
          },
        }}
        autocompletionRequest={{
          bounds: [
            { lat: 50, lng: 50 },
            { lat: 100, lng: 100 },
          ],
        }}
        debounce={300}
        onLoadFailed={(error) =>
          console.error('Could not inject Google script', error)
        }
        i18nIsDynamicList
      />
    </Flex>
  );
}
