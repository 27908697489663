import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ITask } from 'services/@types';
import TaskCard from './TaskCard';
import NoNotification from 'assets/img/systemNotification/noNotification.png';

export default function TaskList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () => await dispatch(getTasks({})),
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      maxW={{ base: '100%', md: '320px' }}
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      // maxH="580px"
      overflow="hidden"
      gap={3}>
      <Text variant="cardTitle">{t('tasks.title')}</Text>
      <HStack justifyContent="start">
        <Text variant="eventCardTitle">{t('tasks.system_notification')}</Text>
      </HStack>
      <Image
        alignSelf="center"
        src={NoNotification}
        alt=""
        mt="24px"
        w="214px"
        h="168.04px"
      />
      <HStack justifyContent="center" pb="50px">
        <Text variant="taskCardSubTitle" color="brand.300">
          {t('tasks.no_system_notification')}
        </Text>
      </HStack>

      <HStack justifyContent="space-between">
        <Text variant="eventCardTitle">{t('tasks.personal_list')}</Text>
        {tasks?.length > 0 && (
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate(`/main/tasks`)}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        )}
      </HStack>
      {isLoading && (
        <HStack justifyContent="center" height="auto" align="center">
          <Spinner />
        </HStack>
      )}
      <VStack gap="8px" height="100%" overflowY="auto" pb="17px">
        {tasks?.length === 0 && (
          <VStack pt="50px" height="100%" w="100%">
            <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
            <Button onClick={() => navigate('/main/tasks')} variant="h1cta">
              {t('tasks.create_new_task')}
            </Button>
          </VStack>
        )}
        {tasks?.map((task: ITask) => (
          <TaskCard key={task.id} task={task} />
        ))}
      </VStack>
    </Card>
  );
}
