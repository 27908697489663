// Chakra imports
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import BrilliantLogo from '../../../assets/img/sidebar/logo.png';
import { HSeparator } from 'components/separator/Separator';
import { useNavigate } from 'react-router-dom';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      w="full"
      cursor={'pointer'}
      onClick={() => navigate('/main/dashboard/overview')}>
      <Image src={BrilliantLogo} alt="" padding="0 44px" mb={33} mt={43} />
      <Text
        display={
          mini === false
            ? 'none'
            : mini === true && hovered === true
            ? 'none'
            : 'block'
        }
        fontSize={'30px'}
        fontWeight="800"
        color={logoColor}>
        H
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
