import {
  Button,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { closeAlertDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsExclamationTriangleFill } from 'react-icons/bs';
import { FiCheck, FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

export default function AlertDialog() {
  const dialog = useSelector((state: RootState) => state.dialogs.alertDialog);
  const { title, content, onClose, onConfirm, cancelLabel, confirmLabel } =
    dialog || {};
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const redColor = useColorModeValue('red.500', 'red.400');
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeAlertDialog());
  }, [dispatch, onClose]);
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius={'2xl'}
        p={4}
        bg="#FFAF36"
        maxW={{ base: 250, md: 'lg' }}>
        <HStack
          px={2}
          spacing={0}
          gap={{ base: 6, md: 4 }}
          flexDirection={{ base: 'column', md: 'row' }}
          align={{ sm: 'center', md: 'start' }}>
          <Icon as={BsExclamationTriangleFill} fontSize={24} color="white" />
          <VStack align="start" flex={1}>
            {title && (
              <Text fontWeight={'bold'} fontSize="xl" color="white">
                {t(title)}
              </Text>
            )}
            <Text fontWeight={'bold'} fontSize="lg" color="white">
              {t(content)}
            </Text>
          </VStack>
          <HStack
            alignSelf={'center'}
            justify="center"
            w={{ base: '100%', md: 'fit-content' }}
            gap={{ base: 6, md: 2 }}>
            <VStack
              w="100%"
              display={cancelLabel || confirmLabel ? 'flex' : 'none'}
              gap={2}>
              {cancelLabel && (
                <Button w="100%" color={'gray.500'} onClick={handleClose}>
                  {cancelLabel}
                </Button>
              )}
              {confirmLabel && (
                <Button w="100%" color={redColor} onClick={onConfirm}>
                  {confirmLabel}
                </Button>
              )}
            </VStack>
            {!cancelLabel && (
              <IconButton
                color={redColor}
                onClick={handleClose}
                aria-label="Close"
                variant="action"
                size="sm"
                borderRadius={'xl'}
                bg="white"
                fontSize={20}
                icon={<FiX />}
              />
            )}

            {!cancelLabel && (
              <IconButton
                color={'green.400'}
                onClick={onConfirm}
                aria-label="Confirm"
                variant="action"
                bg="white"
                size="sm"
                borderRadius={'xl'}
                fontSize={20}
                icon={<FiCheck />}
              />
            )}
          </HStack>
        </HStack>
      </ModalContent>
    </Modal>
  );
}
