import { Badge, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { IEvent } from 'services/@types';
interface EventCardProps {
  event: IEvent;
  onClick?: (event: IEvent) => void;
  isQuoteEvent?: boolean;
  [key: string]: any;
}
export default function EventCard(props: EventCardProps) {
  const { event, onClick, isQuoteEvent = false, ...rest } = props;
  const { t, i18n } = useTranslation();
  const month = useMemo(
    () =>
      format(new Date(event.dateAndTime), 'MMM', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [event.dateAndTime, i18n.language],
  );
  const date = useMemo(
    () => format(new Date(event.dateAndTime), 'dd'),
    [event.dateAndTime],
  );
  return (
    <Flex pos="relative" w="100%" {...rest}>
      <VStack
        width="100%"
        border="1px solid"
        borderColor={!isQuoteEvent ? `tag.${'new'}` : '#5FBF96'}
        borderRadius="16px"
        transition="all 0.3s ease"
        cursor="pointer"
        _hover={{ bg: '#F5F5F5' }}
        //   pb={!isQuoteEvent && '20px'}
        py={!isQuoteEvent ? 6 : 2}
        onClick={() => onClick && onClick(event)}>
        <Flex w="100%" gap={5} px={6}>
          <VStack textAlign="center" spacing={0} justifyContent="center">
            <Text variant="eventCardDate" fontSize="12px">
              {month}
            </Text>
            <Text variant="eventCardDate">{date}</Text>
          </VStack>
          <VStack alignItems="start" justifyContent="center" spacing={0}>
            <Text variant="eventCardTitle">
              {event.celebrant ||
                `${event.contactDetails.firstName} ${event.contactDetails.lastName}`}
            </Text>
            {!isQuoteEvent && (
              <Text variant="eventCardSubTitle">{event.name}</Text>
            )}
            <HStack>
              {event?.numberOfGuests && (
                <HStack>
                  <Icon as={HiOutlineMailOpen} color="general.100" />
                  <Text
                    color="general.100"
                    noOfLines={1}
                    pt={1}
                    fontWeight="400"
                    fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                    wordBreak={'break-word'}>
                    {t('create_event.modals.found_event.invited', {
                      numberOfGuests: event?.numberOfGuests,
                    })}
                  </Text>
                </HStack>
              )}
              {event?.location?.label && (
                <HStack>
                  <Icon as={IoLocationOutline} color="general.100" />
                  <Text
                    color="general.100"
                    noOfLines={1}
                    pt={0}
                    fontWeight="400"
                    fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                    wordBreak={'break-word'}>
                    {event?.location?.label}
                  </Text>
                </HStack>
              )}
            </HStack>
          </VStack>
        </Flex>
      </VStack>
      {!isQuoteEvent && (
        <HStack justifyContent="end" pos="absolute" top="0" px={4} width="100%">
          <Badge
            borderBottomLeftRadius="15px"
            borderBottomRightRadius="15px"
            borderTopLeftRadius="0px"
            borderTopRightRadius="0px"
            bg={`tag.${'new'}`}
            paddingLeft="15px"
            paddingRight="15px">
            {t(`create_event.tags.${'new'}`)}
          </Badge>
        </HStack>
      )}
    </Flex>
  );
}
