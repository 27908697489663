import { Flex, FlexProps, Icon } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
interface EditBtnProps {
  onClick: () => void;
  containerProps?: FlexProps;
  iconProps?: any;
}
/**
 * default behavior of EditBtn component
 * parent must have role equal to group to make the hover effect work
 */
export default function EditBtn(props: EditBtnProps) {
  const { onClick, containerProps, iconProps } = props;
  return (
    <Flex
      w="32px"
      h="32px"
      justify="center"
      align="center"
      borderRadius="10px"
      pos="absolute"
      cursor="pointer"
      opacity={{ base: 0, sm: 1, md: 0 }}
      _groupHover={{ opacity: 1 }}
      transition="all 0.2s linear"
      bg="rgba(1, 1, 1, 0.3)"
      {...containerProps}
      onClick={onClick}>
      <Icon as={MdEdit} w="20px" h="20px" color="#FFFFFF" {...iconProps} />
    </Flex>
  );
}
