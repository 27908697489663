// Chakra imports
import {
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Show,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Assets
import Background from 'assets/img/auth/get_started.jpeg';
import DefaultAuth from 'layouts/auth/variants/Default';
import { useTranslation } from 'react-i18next';
import { FcGoogle } from 'react-icons/fc';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/svg/brilliant-logo.svg';
import Done from '../../../assets/svg/done-icon.svg';
import AuthService from '../../../services/auth.api';
const FEATURES_ELEMENT_TEXTS = [
  'get_started.free',
  'get_started.create_manage',
  'get_started.not_event_producer',
];

function GetStarted() {
  const navigate = useNavigate();
  // Chakra color mode
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );

  const { t } = useTranslation();

  return (
    <DefaultAuth image={Background}>
      <Flex
        w={{ base: '100%', lg: '50%' }}
        h="100%"
        justifyContent="center"
        flexDirection="column"
        gap={10}
        align="center"
        // px={{ base: '20px', sm: '20px', md: '60px', lg: '120px', xl: '150px' }}
      >
        <Spacer />
        <Flex
          flexDirection={'column'}
          align="flex-start"
          flex={1}
          maxW={320}
          justify="end">
          <Show above="md">
            <Image src={Logo} mb={16} alt="logo" w="216px" />
          </Show>
          <Text fontWeight={'bold'} fontSize="2xl">
            {t('get_started.title')}
          </Text>
          <Text color="primary.100" fontWeight="400" fontSize="md" mb={10}>
            {t('get_started.desc')}
          </Text>
          {FEATURES_ELEMENT_TEXTS.map((text, index) => (
            <Flex alignItems="center" gap={2} key={index} mb={2}>
              <Image src={Done} alt="" />
              <Text fontWeight="400" fontSize="md" color="primary.100">
                {t(text)}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Spacer />
        <VStack
          w="100%"
          flex={1}
          maxW={330}
          align="start"
          justify={'end'}
          spacing={'14px'}>
          <Button
            bg="#FFFFFF"
            variant="google"
            w="100%"
            onClick={AuthService.signInWithGoogle}
            leftIcon={<Icon as={FcGoogle} />}
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            {t('get_started.sign_up_with_google')}
          </Button>
          <Button
            variant="h1cta"
            onClick={() => navigate('/auth/sign-up')}
            w="100%">
            {t('get_started.sign_up_with_email')}
          </Button>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%">
            <Text color="primary.100" fontWeight="400" fontSize="xs">
              {t('get_started.already_a_sign_up')}
              <NavLink to="/auth/sign-in">
                <Text color="#aa915d" as="span" ms="5px" fontWeight="700">
                  {t('get_started.log_in')}
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </VStack>
        <Spacer />
      </Flex>
    </DefaultAuth>
  );
}

export default GetStarted;
