import {
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  AppDialogProps,
  closeEventFoundDialog,
} from 'contexts/redux/dialog/dialogsSlice';
// Custom components
import { RootState } from 'contexts/redux/store';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoWarning } from 'react-icons/io5';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { MdCheck, MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
// import { DetailInfoItem } from './DetailInfoItem';

export interface EventFoundModalProps {
  onClose: () => void;
}

const DetailInfoItem: React.FC<{
  label: string;
  value: string;
  [key: string]: any;
}> = ({ label, value, ...rest }) => {
  return (
    <HStack>
      <Text variant="detailInfoLabel">{label}</Text>
      <Text variant="detailInfoValue" {...rest}>
        {value}
      </Text>
    </HStack>
  );
};

export const EventFoundModal: React.FC<EventFoundModalProps> = ({
  onClose,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const eventFoundDialog = useSelector(
    (state: RootState) => state.dialogs.eventFoundDialog,
  );
  const dialog = eventFoundDialog as AppDialogProps;

  const eventDate = useMemo(
    () =>
      dialog?.event?.dateAndTime
        ? format(new Date(dialog?.event?.dateAndTime), 'MMMM dd, yyyy hh:mm a')
        : null,
    [dialog?.event?.dateAndTime],
  );

  const fullName = useMemo(
    () =>
      dialog?.event?.contactDetails?.firstName +
      ' ' +
      dialog?.event?.contactDetails?.lastName,
    [
      dialog?.event?.contactDetails?.firstName,
      dialog?.event?.contactDetails?.lastName,
    ],
  );

  return (
    <Modal isOpen={!!eventFoundDialog} onClose={onClose} size={'lg'} isCentered>
      <ModalOverlay bg={'#000000d3'} />
      <ModalContent
        borderRadius={'2xl'}
        overflow="hidden"
        mx={2}
        borderColor="orange.400"
        borderWidth={1}>
        <Flex
          py={{ base: '10px', md: '30px' }}
          px={{ base: '12px', md: '34px' }}
          borderColor={'orange.400'}
          align="center"
          gap={{ sm: 1, md: 2 }}
          ps={{ base: '10px', sm: '60px' }}>
          <Flex gap={0} flexDirection="column">
            <HStack align="center">
              <Text
                color={textColor}
                fontSize={{ base: 'sm', md: 'xl' }}
                fontWeight="700"
                noOfLines={1}>
                {dialog?.event?.name}
              </Text>
              <Text
                color="brand.900"
                noOfLines={1}
                pt={1}
                fontWeight="700"
                fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                wordBreak={'break-word'}>
                {t(`event_types.${dialog?.event?.type}`)}
              </Text>
            </HStack>
            <Flex gap={0} flexDirection="column">
              <HStack align="center">
                <Text variant="cardSectionTitle" noOfLines={1}>
                  {eventDate}
                </Text>
                {dialog?.event?.numberOfGuests && (
                  <HStack>
                    <Icon as={HiOutlineMailOpen} color="general.100" />
                    <Text
                      color="general.100"
                      noOfLines={1}
                      pt={1}
                      fontWeight="400"
                      fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                      wordBreak={'break-word'}>
                      {t('create_event.modals.found_event.invited', {
                        numberOfGuests: dialog?.event?.numberOfGuests,
                      })}
                    </Text>
                  </HStack>
                )}
                <HStack>
                  <Icon as={IoLocationOutline} color="general.100" />
                  <Text
                    color="general.100"
                    noOfLines={1}
                    pt={0}
                    fontWeight="400"
                    fontSize={{ base: 'sm', sm: 'xs', '2xl': 'sm' }}
                    wordBreak={'break-word'}>
                    {dialog?.event?.location?.label}
                  </Text>
                </HStack>
              </HStack>
            </Flex>
          </Flex>
          <Flex align="center" ms="auto" />
        </Flex>
        <Flex
          w="100%"
          flex={1}
          px={{ base: '10px', md: '20px' }}
          position="relative">
          <Flex overflow="hidden" w="100%">
            <Flex
              direction="column"
              w="100%"
              boxSizing="border-box"
              gap={6}
              p={6}>
              <Divider />
              <DetailInfoItem
                label={t('create_event.modals.found_event.full_name')}
                value={fullName}
                color="orange.400"
              />
              <DetailInfoItem
                label={t('create_event.modals.found_event.phone')}
                value={dialog?.event?.contactDetails?.phone}
              />
              <DetailInfoItem
                label={t('create_event.modals.found_event.address')}
                value={dialog?.event?.contactDetails?.address}
              />
              <DetailInfoItem
                label={t('create_event.modals.found_event.id')}
                value={dialog?.event?.contactDetails?.idNumber}
                color="orange.400"
              />
              <DetailInfoItem
                label={t('create_event.modals.found_event.email')}
                value={dialog?.event?.contactDetails?.email}
              />
            </Flex>
          </Flex>
        </Flex>
        <HStack
          bg={'orange.400'}
          py={4}
          px={5}
          gap={{ sm: 2, md: 0 }}
          align={{ base: 'center', sm: 'center', md: 'start' }}
          flexDirection={{ base: 'column', md: 'row' }}>
          <Icon as={IoWarning} color="white" fontSize={'22px'} />
          <VStack align={{ sm: 'center', md: 'start' }} spacing={0}>
            <Text color={'white'}>
              {t('create_event.modals.found_event.title')}
            </Text>
            <Text color="white" fontWeight="bold">
              {t('create_event.modals.found_event.question')}
            </Text>
          </VStack>
          <Spacer />
          <HStack gap={2} alignSelf="center">
            <IconButton
              aria-label="approve-merge"
              color="green.300"
              p={1}
              borderRadius={'xl'}
              size="sm"
              onClick={dialog?.onConfirm}
              as={MdCheck}
            />
            <IconButton
              size="sm"
              borderRadius={'xl'}
              aria-label="clear"
              color={'red.400'}
              as={MdClear}
              onClick={() => dispatch(closeEventFoundDialog())}
              p={1}
            />
          </HStack>
        </HStack>
      </ModalContent>
    </Modal>
  );
};
