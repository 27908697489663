// chakra imports
import {
    Box,
    Flex,
    Stack
} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';

// FUNCTIONS

function SidebarContent(props: {
    routes: RoutesType[];
    hovered?: boolean;
    mini?: boolean;
}) {
    const { routes, mini, hovered } = props;
    // SIDEBAR
    return (
        <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
            <Brand mini={mini} hovered={hovered} />
            <Stack direction="column" mb="auto" mt="15px" overflow={"scroll"} sx={{ scrollbarWidth: 'none', pb: 100 }}>
                <Box
                    ps={
                        mini === false
                            ? '20px'
                            : mini === true && hovered === true
                                ? '20px'
                                : '16px'
                    }
                    pe={{ md: '16px', '2xl': '1px' }}
                    ms={mini && hovered === false ? '-16px' : 'unset'}
                >
                    <Links mini={mini} hovered={hovered} routes={routes} />
                </Box>
            </Stack>
        </Flex>
    );
}

export default SidebarContent;
