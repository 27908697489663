import { getAvatarBgColor } from 'utils/getAvatarBgColor';

export const AvatarStyles = {
  components: {
    Avatar: {
      variants: {
        main: (props: any) => ({
          container: {
            bg: getAvatarBgColor(props.id),
            color: 'white',
          },
        }),
      },
    },
  },
};
