export const iconButtonStyles = {
  components: {
    Button: {
      baseStyle: {},
      variants: {
        actionIconBlue: {
          h: '32px',
          w: '32px',
          minW: '32px',
          borderRadius: '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#93C3B3',
          _active: {
            bg: '#465D55',
          },
        },
        actionIconGrey: {
          h: '32px',
          w: '32px',
          minW: '32px',
          borderRadius: '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#ACACAC',
          _active: {
            bg: '#00000080',
          },
        },
        actionIconTransparent: {
          h: '32px',
          w: '32px',
          minW: '32px',
          borderRadius: '10px',
          color: '#93C3B3',
          cursor: 'pointer',
          bg: 'transparent',
          _active: {
            color: '#465D55',
            bg: 'transparent',
          },
          _focus: {
            color: '#465D55',
            bg: 'transparent',
          },
        },
        actionIconRed: {
          h: '32px',
          w: '32px',
          minW: '32px',
          borderRadius: '10px',
          color: 'white',
          cursor: 'pointer',
          bg: '#FF4D4F',
          _active: {
            bg: '#cc3e3f',
          },
          _focus: {
            bg: '#cc3e3f',
          },
        },
      },
    },
  },
};
