import { Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { FiPlus } from 'react-icons/fi';
import { useCallback, useMemo } from 'react';
const fields = ['phone', 'address', 'idNumber', 'email'];
const full_name = ['firstName', 'lastName'];
const eventRoles = [
  'planner',
  'vendor',
  'attendee',
  'sponsor',
  'speaker',
  'media',
  'other',
];
interface CreateEventContactDetailsProps {
  handleChange: (key: string, value: any, errorKey?: string) => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function CreateEventContactDetails(
  props: CreateEventContactDetailsProps,
) {
  const { handleChange, event, errorList } = props;
  const { t } = useTranslation();
  const contactDetails = useMemo(
    () => event?.contactDetails || {},
    [event?.contactDetails],
  );
  const currentEventRole = useMemo(
    () => contactDetails?.roll || '',
    [contactDetails?.roll],
  );
  const selectedEventRole = useMemo(
    () => (currentEventRole ? [`event_roles.${currentEventRole}`] : []),
    [currentEventRole],
  );
  const handleChangeEventRole = useCallback(
    (selected: string) => {
      const formattedSelected = selected.replace('event_roles.', '');
      if (selectedEventRole.includes(selected)) {
        handleChange('contactDetails', { ...contactDetails, roll: '' });
        return;
      }
      handleChange(
        'contactDetails',
        {
          ...contactDetails,
          roll: formattedSelected,
        },
        'contactDetails.roll',
      );
    },
    [selectedEventRole, handleChange, contactDetails],
  );
  return (
    <Stack gap={3}>
      <HStack justify="space-between">
        <Text variant="eventSubTitle">{t('create_event.contact_details')}</Text>
        <HStack>
          <Text variant="eventLink">{t('create_event.add_contact')}</Text>
          <Icon
            as={FiPlus}
            bg="brand.400"
            w="32px"
            h="32px"
            cursor="pointer"
            color="white"
            borderRadius="8px"
          />
        </HStack>
      </HStack>
      <Flex wrap="wrap" gap="40px" alignItems="end">
        <Flex gap="18px" w={{ base: '100%', lg: '318px' }}>
          {full_name.map((field, key) => (
            <InputField
              key={key}
              flex="1"
              label={t(`create_event.label.${field}`)}
              placeholder={t(`create_event.placeholder.${field}`)}
              name={field}
              value={contactDetails[field] || ''}
              onChange={(e: any) =>
                handleChange(
                  'contactDetails',
                  {
                    ...contactDetails,
                    [field]: e.target.value,
                  },
                  `contactDetails.${field}`,
                )
              }
              borderColor={
                errorList.includes(`contactDetails.${field}`)
                  ? 'error.100'
                  : 'gray.200'
              }
            />
          ))}
        </Flex>
        {fields.map((field, key) => (
          <InputField
            key={key}
            label={t(`create_event.label.${field}`)}
            placeholder={t(`create_event.placeholder.${field}`)}
            name={field}
            w={{ base: '100%', lg: '318px' }}
            flex="1"
            value={contactDetails[field] || ''}
            onChange={(e: any) =>
              handleChange(
                'contactDetails',
                {
                  ...contactDetails,
                  [field]: e.target.value,
                },
                `contactDetails.${field}`,
              )
            }
            borderColor={
              errorList.includes(`contactDetails.${field}`)
                ? 'error.100'
                : 'gray.200'
            }
          />
        ))}
        <DropdownField
          options={eventRoles.map((role) => `event_roles.${role}`)}
          onSelected={handleChangeEventRole}
          selectedOptions={selectedEventRole}
          placeholder={t('create_event.placeholder.roll_in_event')}
          w={{ base: '100%', lg: '318px' }}
          menuButton={{
            borderColor: errorList.includes('contactDetails.roll')
              ? 'error.100'
              : 'gray.200',
          }}
          zIndex={11}
        />
      </Flex>
    </Stack>
  );
}
