// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEvent } from 'services/@types';
import EventService from 'services/event.api';
import { closeSplashDialog, showSplashDialog } from '../dialog/dialogsSlice';
export interface EventState {
  events: IEvent[];
  event: IEvent | null;
  similarEvents: IEvent[];
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: EventState = {
  events: [],
  event: null,
  similarEvents: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
  },
};

export const getEvents = createAsyncThunk(
  'events/getEvents',
  async (params: Record<string, any>, { dispatch, rejectWithValue }) => {
    dispatch(showSplashDialog({}));
    try {
      const response = await EventService.getEvents(params);
      dispatch(closeSplashDialog());
      return response;
    } catch (error) {
      dispatch(closeSplashDialog());
      return rejectWithValue(error);
    }
  },
);

export const getEvent = createAsyncThunk(
  'events/getEvent',
  async (eventId: string, { dispatch }) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.getEvent(eventId);
    dispatch(closeSplashDialog());
    return response;
  },
);

export const createEvent = createAsyncThunk(
  'events/createEvent',
  async (event: IEvent, { dispatch }) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.createEvent(event);
    dispatch(closeSplashDialog());
    return response;
  },
);

export const updateEvent = createAsyncThunk(
  'events/updateEvent',
  async ({ updates, id }: { updates: IEvent; id: string }, { dispatch }) => {
    dispatch(showSplashDialog({}));
    const response = await EventService.updateEvent(id, updates);
    dispatch(closeSplashDialog());
    return response;
  },
);

export const deleteEvent = createAsyncThunk(
  'events/deleteEvent',
  async (eventId: string, { dispatch }) => {
    dispatch(showSplashDialog({}));
    await EventService.deleteEvent(eventId);
    dispatch(closeSplashDialog());
    return eventId;
  },
);

export const getSimilarEvents = createAsyncThunk(
  'events/getSimilarEvents',
  async (params: Record<string, any>, { dispatch }) => {
    const response = await EventService.getSimilarEvents(params);
    return response;
  },
);

const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateEventsList: (state, action) => {
      state.events = action.payload;
    },
    updateSimilarEventsList: (state, action) => {
      state.similarEvents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events = action.payload.results as IEvent[];
        state.pagination = {
          page: action.payload.page,
          limit: action.payload.limit,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        };
        state.loading = false;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(getEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })
      .addCase(getEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(createEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(updateEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        );
        state.loading = false;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter(
          (event) => event.id !== action.payload,
        );
        state.loading = false;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      })
      .addCase(getSimilarEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSimilarEvents.fulfilled, (state, action) => {
        state.similarEvents = action.payload;
        state.loading = false;
      })
      .addCase(getSimilarEvents.rejected, (state, action) => {
        state.error = action.error.message || null;
        state.loading = false;
      });
  },
});

export const { updateEventsList, updateSimilarEventsList } = eventSlice.actions;

export default eventSlice.reducer;
