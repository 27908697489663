import { useToast } from '@chakra-ui/react';
import { closeSplashDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createEvent } from 'contexts/redux/event/eventSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent } from 'services/@types';
const requiredFields = [
  'name',
  'type',
  'location',
  'dateAndTime',
  'contactDetails.firstName',
  'contactDetails.lastName',
  'contactDetails.email',
  'contactDetails.phone',
  'contactDetails.address',
  'contactDetails.roll',
  'contactDetails.idNumber',
];
interface ICreateEventResult {
  handleChange: (key: string, value: any) => void;
  handleCreateEvent: () => void;
  event: Record<string, any>;
  errorList: string[];
}
export default function useCreateEvent(): ICreateEventResult {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [event, setEvent] = useState<Record<string, any>>({});
  const [errorList, setErrorList] = useState<string[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const validateEvent = useCallback(() => {
    const result = requiredFields.filter((field) => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        return !event[parent]?.[child];
      }
      return !event[field];
    });
    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [event]);
  const handleCreateEvent = useCallback(async () => {
    const isError = validateEvent();
    if (isError) {
      return;
    }
    const response = await dispatch(
      createEvent({ ...event, curator: user?.id } as IEvent),
    );
    if (response.payload) {
      setEvent({});
      toast({
        variant: 'main',
        title: t('create_event.toast.event_added'),
      });
    }
    if (response.error) {
      dispatch(closeSplashDialog());
    }
  }, [event, dispatch, user?.id, toast, t, validateEvent]);
  const handleChange = useCallback(
    (key: string, value: any, errorKey?: string) => {
      if (errorList.includes(key)) {
        setErrorList((prev) => prev.filter((error) => error !== key));
      }
      if (errorKey && errorList.includes(errorKey)) {
        setErrorList((prev) => prev.filter((error) => error !== errorKey));
      }
      setEvent((prev) => ({ ...prev, [key]: value }));
    },
    [errorList],
  );
  return { handleChange, handleCreateEvent, event, errorList };
}
