import { Flex } from '@chakra-ui/react';
import TaskList from './components/TaskList';

export default function Dashboard() {
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch" height="100%">
        <Flex wrap="wrap" gap={5} height="100%">
          <TaskList />
        </Flex>
      </Flex>
    </Flex>
  );
}
