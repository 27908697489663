// Chakra imports
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDimensions,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
// Custom components

export default function Default(props: {
  id?: string;
  label?: string;
  error?: string;
  extra?: JSX.Element;
  placeholder?: string | JSX.Element;
  type?: string;
  isDisabled?: boolean;
  maxItems?: number;
  [x: string]: any;
}) {
  const {
    id,
    label,
    extra,
    error,
    placeholder,
    type,
    options,
    selectedOptions,
    onSelected,
    topItem,
    hideLabel,
    mb,
    menuButton,
    formLabelStyles,
    isDisabled = false,
    maxItems,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('primary.100', 'white');
  const { t } = useTranslation();
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(dropdownContainerRef);
  const [isSelectionFull, setIsSelectionFull] = useState<boolean>(false);

  const getIsDisabled = useCallback(
    (option) =>
      isSelectionFull &&
      !selectedOptions?.includes(
        (option as { id: string })?.id || (option as string),
      ),
    [isSelectionFull, selectedOptions],
  );

  useEffect(() => {
    if (!maxItems) {
      return;
    }
    setIsSelectionFull(maxItems === selectedOptions.length);
  }, [maxItems, selectedOptions]);

  return (
    <Flex direction="column" {...rest} ref={dropdownContainerRef}>
      {label && (
        <FormLabel
          display={hideLabel ? 'none' : 'flex'}
          htmlFor={id}
          mb={0}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="500"
          _hover={{ cursor: 'pointer' }}
          {...formLabelStyles}>
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px" height={'28px'}>
            {extra}
          </Text>
        </FormLabel>
      )}
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          textAlign="start"
          borderRadius={{ base: '16px', md: '2xl' }}
          _active={{ borderColor: 'brand.600' }}
          border="1px solid"
          borderColor={'gray.300'}
          h="50px"
          maxH="50px"
          disabled={isDisabled}
          fontWeight="500"
          {...menuButton}>
          {placeholder}
        </MenuButton>
        <MenuList
          zIndex={999}
          minWidth={dimensions?.contentBox?.width}
          style={{ maxHeight: 300, overflow: 'scroll' }}
          borderRadius="2xl">
          {topItem && <MenuItem>{topItem}</MenuItem>}
          {options?.map(
            (
              option: { id: string; label: string; icon: JSX.Element } | string,
            ) => (
              <MenuItem
                key={(option as { id: string })?.id || (option as string)}
                onClick={() => onSelected(option)}
                w="100%"
                isDisabled={getIsDisabled(option)}>
                <HStack w="100%">
                  {/* <Text>{option?.id ? option?.label : t(option)}</Text> */}
                  {(option as { label: string; icon?: JSX.Element })?.label ? (
                    <>
                      <Text
                        fontWeight={
                          selectedOptions?.includes(
                            (option as { id: string })?.id ||
                              (option as string),
                          ) && '500'
                        }>
                        {t(
                          (option as { label: string; icon?: JSX.Element })
                            ?.label,
                        )}
                      </Text>
                      {(option as { label: string; icon?: JSX.Element })?.icon}
                    </>
                  ) : (
                    <Text
                      fontWeight={
                        selectedOptions?.includes(
                          (option as { id: string })?.id || (option as string),
                        ) && '500'
                      }>
                      {t(option as string)}
                    </Text>
                  )}
                  <Flex flex={1} />
                  <Icon
                    as={MdCheck}
                    color="brand.400"
                    fontSize="xl"
                    display={
                      selectedOptions?.includes(
                        (option as { id: string })?.id || (option as string),
                      )
                        ? 'block'
                        : 'none'
                    }
                  />
                </HStack>
              </MenuItem>
            ),
          )}
        </MenuList>
      </Menu>
      <Text
        fontSize="xs"
        color="red.500"
        mt="2px"
        fontWeight={'semibold'}
        display={error ? 'block' : 'none'}>
        {t(error)}
      </Text>
    </Flex>
  );
}
