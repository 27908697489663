import { AxiosInstance, AxiosResponse } from 'axios';
import { ITask, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class TaskService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createTask(ITaskRequest: ITask): Promise<ITask> {
    const response: AxiosResponse<ITask> = await this.api.post(
      '/tasks',
      ITaskRequest,
    );
    return response.data;
  }

  public async getTasks(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<ITask>> {
    const response: AxiosResponse<PaginatedResults<ITask>> = await this.api.get(
      '/tasks',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getTask(id: string): Promise<ITask> {
    const response: AxiosResponse<ITask> = await this.api.get(`/tasks/${id}`);
    return response.data;
  }

  public async updateTask(id: string, updates: Partial<ITask>): Promise<ITask> {
    const response: AxiosResponse<ITask> = await this.api.patch(
      `/tasks/${id}`,
      updates,
    );
    return response.data;
  }

  public async deleteTask(id: string): Promise<void> {
    await this.api.delete(`/tasks/${id}`);
  }
}

const _ITaskService = new TaskService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _ITaskService;
