// Chakra imports
import {
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
// Assets
import AddressIcon from 'assets/svg/supplierInfo/address-icon.svg';
import EmailIcon from 'assets/svg/supplierInfo/email-icon.svg';
import favRoundedIcon from 'assets/svg/supplierInfo/favorite-rounded-icon.svg';
import PhoneIcon from 'assets/svg/supplierInfo/phone-icon.svg';
import VatIcon from 'assets/svg/supplierInfo/vat-icon.svg';
import AuthImage from 'components/authImage/AuthImage';
import ThreeDotsBtn from 'components/buttons/ThreeDotsBtn';
import { setAskForQuoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { updateSupplierIsFavorite } from 'contexts/redux/supplier/supplierSlice';
import { useBusinessContact } from 'hooks/useBusinessContact';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Supplier } from 'services/@types';
import { DetailInfoItem } from './DetailInfoItem';

export default function SupplierDetails(props: {
  status: string;
  name: string;
  onEditSupplier: (supplier: Supplier) => void;
  onDeleteSupplier: (supplier: Supplier) => void;
  [x: string]: any;
}) {
  const { status, name, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const supplier = useSelector(
    (state: RootState) => state.suppliers.currentSupplier,
  );
  const { businessContact, business } = useBusinessContact(supplier);
  const { loading } = useSelector((state: RootState) => state.suppliers);
  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  // Chakra Color Mode
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' },
  );
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.900', 'white');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  );
  console.log('business contact ->', businessContact);
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  console.log('#Business ->', business);

  const updateIsFavorite = useCallback(async () => {
    await dispatch(
      updateSupplierIsFavorite({
        supplierId: supplier?.id,
        isFavorite: !supplier?.isFavorite,
      }),
    );
  }, [supplier?.id, supplier?.isFavorite, dispatch]);
  const handleOpenAskForQuote = useCallback(() => {
    dispatch(setAskForQuoteDialog({}));
  }, [dispatch]);

  return (
    <Flex flexDirection="column" {...rest}>
      <Flex
        px={{ base: '12px', md: '34px' }}
        borderColor={borderColor}
        align="center"
        gap={{ sm: 1, md: 2 }}
        ps={{ base: '10px', sm: '42px' }}>
        <AuthImage
          h={{ base: '40px', md: '60px' }}
          w={{ base: '40px', md: '60px' }}
          transition={'all 0.2s ease'}
          borderRadius={'full'}
          size={'md'}
          name={supplier?.name}
          me="16px"
          path={business?.media.find((m) => m.type === 'profileImage')?.url}
          isAvatar
          id={business?.id || supplier?.id}
        />

        <Flex gap={0} flexDirection="column">
          <Text
            color={textColor}
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight="700"
            noOfLines={1}>
            {supplier?.name}
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: 'xs', md: 'md' }}
            fontWeight="300"
            minH={5}>
            {businessContact?.fullName}
          </Text>
          <Text
            color={brandColor}
            fontSize={{ base: 'xs', md: 'sm' }}
            fontWeight="400"
            cursor="pointer"
            textDecorationLine={'underline'}>
            {t('suppliers.see_past_events')}
          </Text>
        </Flex>
        <Flex align="center" ms="auto" />
        <Button variant={'h3outlinedWhiteGreen'} px={'12px !important'}>
          {t('suppliers.view_page')}
        </Button>
        <Menu isOpen={isOpen1} onClose={onClose1}>
          <ThreeDotsBtn onClick={onOpen1} me="8px" mb="0px" />
          <MenuList
            minW="unset"
            maxW="180px !important"
            border="transparent"
            backdropFilter="blur(63px)"
            bg={bgList}
            boxShadow={'2xl'}
            borderRadius="20px">
            <MenuItem
              transition="0.2s linear"
              color={textColor}
              onClick={() => props?.onEditSupplier(supplier)}
              _hover={{
                bg: 'gray.200',
              }}
              _active={{
                bg: 'gray.300',
              }}
              _focus={{
                bg: 'transparent',
              }}
              fontSize="sm">
              {t('suppliers.actions.edit')}
            </MenuItem>
            <MenuItem
              transition="0.2s linear"
              onClick={() => props.onDeleteSupplier(supplier)}
              _hover={{
                bg: 'red.100',
              }}
              _active={{
                bg: 'transparent',
              }}
              _focus={{
                bg: 'transparent',
              }}
              fontSize="sm"
              color="red.500">
              {t('suppliers.actions.delete')}
            </MenuItem>
          </MenuList>{' '}
        </Menu>
      </Flex>

      <Flex
        w="100%"
        flex={1}
        px={{ base: '10px', md: '20px' }}
        position="relative">
        <Flex overflow="hidden" w="100%">
          <Flex
            direction="column"
            w="100%"
            boxSizing="border-box"
            gap={6}
            p={6}>
            <Divider />
            <HStack>
              {supplier?.isFavorite && (
                <Button
                  leftIcon={<Image src={favRoundedIcon} w={'24px'} h="24px" />}
                  variant={'h3outlinedGreen'}
                  onClick={() => updateIsFavorite()}
                  isLoading={loading}>
                  {t('suppliers.favorite')}
                </Button>
              )}
              {!supplier?.isFavorite && (
                <Button
                  variant={'h3outlinedGreen'}
                  onClick={() => updateIsFavorite()}
                  isLoading={loading}>
                  {t('suppliers.add_to_favorite')}
                </Button>
              )}
              <Button
                variant={'h1cta'}
                display={supplier?.businessID ? 'flex' : 'none'}
                onClick={handleOpenAskForQuote}>
                {t('suppliers.ask_for_quote')}
              </Button>
            </HStack>
            <Text>{supplier?.internalNotes}</Text>
            <Divider w="100%" />
            <Text>{supplier?.tags}</Text>
            <Text variant="eventCardTitle">
              {t('suppliers.company_details')}
            </Text>
            <DetailInfoItem
              icon={<Image src={EmailIcon} />}
              value={supplier?.email}
            />
            <DetailInfoItem
              icon={<Image src={PhoneIcon} />}
              value={supplier?.phone}
            />
            <DetailInfoItem
              icon={<Image src={VatIcon} />}
              value={supplier?.id}
            />
            <DetailInfoItem
              icon={<Image src={AddressIcon} />}
              value={supplier?.address}
            />
            {businessContact?.fullName && (
              <Text variant="eventCardTitle">
                {t('suppliers.details', {
                  fullName: businessContact?.fullName,
                })}
              </Text>
            )}
            <DetailInfoItem
              icon={<Image src={EmailIcon} />}
              value={businessContact?.email}
            />
            <DetailInfoItem
              icon={<Image src={PhoneIcon} />}
              value={businessContact?.phone}
            />
          </Flex>
        </Flex>
      </Flex>
      {/* <Card
        w="90%"
        justifyContent={'center'}
        alignItems={'center'}
        boxShadow={'md'}
        alignSelf="center"
      >
        <HStack>
          <Button leftIcon={<FaHandHoldingUsd />} variant="ghost" size="sm">
            {t('suppliers.amount_due')}
          </Button>
          <Badge
            colorScheme={'red'}
            borderRadius="6px"
            py={3}
            color="red.400"
            fontWeight={'500'}
          >
            10,000 Nis
          </Badge>
        </HStack>
      </Card> */}
    </Flex>
  );
}
