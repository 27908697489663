export const TextStyles = {
  components: {
    Text: {
      baseStyle: {
        letterSpacing: '-0.02rem',
      },
      variants: {
        cardTitle: () => ({
          fontSize: '24px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
        }),
        cardSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        cardSectionTitle: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        cardSectionInfo: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.8)',
          ps: '8px',
        }),
        serviceTitle: () => ({
          fontSize: '13px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.4)',
        }),
        eventSubTitle: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: 'brand.900',
        }),
        eventLink: () => ({
          fontSize: '14px',
          fontWeight: '500',
          color: 'brand.400',
          cursor: 'pointer',
          textDecoration: 'underline',
        }),
        errorTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: 'error.100',
        }),
        detailInfoLabel: () => ({
          fontSize: '16px',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.8)',
        }),
        detailInfoValue: () => ({
          fontSize: '16px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.6)',
        }),
        eventCardDate: () => ({
          fontSize: '24px',
          fontWeight: '400',
          color: 'brand.900',
          lineHeight: '21px',
        }),
        eventCardTitle: () => ({
          fontSize: '14px',
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
        eventCardSubTitle: () => ({
          fontSize: '12px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
        taskCardDate: () => ({
          fontSize: '24px',
          fontWeight: '500',
          color: '#93C3B3',
          lineHeight: '21px',
        }),
        taskCardSubTitle: () => ({
          fontSize: '14px',
          fontWeight: '400',
          color: 'rgba(0, 0, 0, 0.6)',
          wordBreak: 'break-word',
          noOfLines: 1,
        }),
      },
    },
  },
};
