export const AlertStyles = {
  components: {
    Alert: {
      variants: {
        main: () => ({
          container: {
            bgGradient: {
              base: 'linear(to-b, brand.400, brand.600)',
              sm: 'linear(to-r, brand.400, brand.600)',
              md: 'linear(to-l, brand.400, brand.600)',
              lg: 'linear(to-l, brand.400, brand.600)',
              xl: 'linear(to-l, brand.400, brand.600)',
            },
            color: 'brand.300',
          },
        }),
      },
    },
  },
};
