import { useToast } from '@chakra-ui/react';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createTask } from 'contexts/redux/task/taskSlice';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IEvent } from 'services/@types';
const requiredFields = ['name', 'event'];
interface UseCreateTaskResult {
  task: Record<string, any>;
  event: IEvent;
  handleChangeTask: (e: any) => void;
  handleCreateTask: () => void;
  handleOpenEventDialog: () => void;
  errorList: string[];
}
export default function useCreateTask(): UseCreateTaskResult {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<IEvent>(null);
  const [task, setTask] = useState<Record<string, any>>(null);
  const [errorList, setErrorList] = useState<string[]>([]);
  const handleChangeTask = useCallback(
    (e: any) => {
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }
      setTask((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [errorList],
  );
  const validateTask = useCallback(() => {
    let result = requiredFields.filter((field) => !task || !task[field]);

    if (event) {
      result = result.filter((field) => field !== 'event');
    }

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [task, event]);
  const handleCreateTask = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }
    const guests: string[] = [];
    const newTaskBody = { ...task, event: event?.id, guests };
    const newTask = await dispatch(createTask(newTaskBody));
    if (newTask?.error) {
      return;
    }
    setTask(null);
    setEvent(null);
    toast({
      title: t('create_task.task_created'),
      variant: 'main',
    });
    navigate('/main/dashboard/overview');
  }, [toast, t, task, event?.id, dispatch, validateTask, navigate]);
  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);
  return {
    task,
    event,
    handleChangeTask,
    handleCreateTask,
    handleOpenEventDialog,
    errorList,
  };
}
