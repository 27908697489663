import { AxiosError } from 'axios';
import { clearCredentials } from 'contexts/redux/auth/authSlice';
import {
  closeSplashDialog,
  showSplashDialog,
} from 'contexts/redux/dialog/dialogsSlice';
import useQueryParam from 'hooks/useQueryParam';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getErrorMessageKey } from 'utils/getErrorMessageKey';
import AuthService from '../../../../services/auth.api';
const emailRegexValidation = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const useSignIn = () => {
  const [loading, setLoading] = useState(false);

  const errorParam = useQueryParam('error');

  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState<Record<string, string>>();

  const signIn = useCallback(async () => {
    if (!emailRegexValidation.test(inputValues?.email)) {
      setError('Invalid email address');
      return;
    }

    setLoading(true);

    dispatch(showSplashDialog({ title: 'dialogs.splash.signning_in' }));
    setTimeout(async () => {
      try {
        await AuthService.login({
          email: inputValues?.email,
          password: inputValues?.password,
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.status === 401) {
          setError('signin.error');
        } else {
          const errorMessageKey = getErrorMessageKey(
            error.response?.status || 400,
          );
          setError(errorMessageKey);
        }
      }

      dispatch(closeSplashDialog());
    }, 1000);

    setLoading(false);
  }, [dispatch, inputValues?.email, inputValues?.password]);

  const signInWithGoogle = useCallback(async () => {
    AuthService.logout();
    AuthService.signInWithGoogle();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return {
    handleChange,
    signInWithGoogle,
    inputValues,
    signIn,
    loading,
    setLoading,
    error,
    errorParam,
    setError,
  };
};
