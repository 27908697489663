import {
  fetchUser,
  useAuthToken,
  useMyUser,
} from 'contexts/redux/auth/authSlice';
import { fetchBusiness } from 'contexts/redux/business/businessSlice';
import { fetchInvites } from 'contexts/redux/invite/inviteSlice';
import { RootState } from 'contexts/redux/store';
import { fetchSuppliers } from 'contexts/redux/supplier/supplierSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useUserInfoLoader = () => {
  const dispatch = useDispatch<any>();
  const authToken = useAuthToken();
  const user = useMyUser();
  const { loading, business } = useSelector(
    (state: RootState) => state.businesses,
  );
  const [loaders, setLoaders] = useState<{
    businessLoader: false;
  }>();

  useEffect(() => {
    if (
      authToken &&
      !loaders?.businessLoader &&
      !business &&
      user?.businessID
    ) {
      dispatch(fetchBusiness(user.businessID));
      dispatch(fetchUser(user.id));
      dispatch(fetchSuppliers({}));
      dispatch(fetchInvites({}));
    }
  }, [
    authToken,
    business,
    dispatch,
    loaders?.businessLoader,
    user?.businessID,
    user?.id,
  ]);

  useEffect(() => {
    setLoaders((prev: any) => ({
      ...prev,
      businessLoader: loading,
    }));
  }, [loading]);

  return { loaders };
};
