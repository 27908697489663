import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Business } from 'services/@types';
import { updateBusiness as update } from '../../../../contexts/redux/business/businessSlice';

function useUpdateBusiness(myBusiness: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] =
    useState<Record<string, any>>(myBusiness);
  const dispatch = useDispatch<any>();
  const toast = useToast();

  useEffect(() => {
    setInputValues(myBusiness);
  }, [myBusiness]);
  const handleChange = useCallback(
    (event: { target: { name: string; value: string | any } }) => {
      const { name, value } = event.target;
      console.log('# handleChange ->', name, value);
      setInputValues((prevState) => ({ ...prevState, [name]: value }));
    },
    [],
  );

  const updateBusiness = useCallback(async () => {
    setLoading(true);
    console.log('# update business ->', inputValues);
    try {
      let payload: Partial<Business> = {};
      Object.keys(inputValues).map((key) => {
        if (myBusiness[key] !== inputValues[key]) {
          payload = { ...payload, [key]: inputValues[key] };
        }
      });
      // detect if the business has changed
      if (Object.keys(payload).length === 0) {
        setLoading(false);
        return true;
      }
      const response = await dispatch(
        update({ businessId: inputValues?.id, updates: payload }),
      );
      if (!response.error) {
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.business_updated'),
          status: 'success',
          duration: 2000,
          variant: 'main',
        });
        setLoading(false);
        return true;
      } else {
        setError(response.error.message);
        toast({
          title: t('toast.title.error'),
          description: response.payload.response.data.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, dispatch, myBusiness, toast, t]);

  return {
    inputValues,
    handleChange,
    updateBusiness,
    setInputValues,
    loading,
    setLoading,
    error,
  };
}

export default useUpdateBusiness;
