import { Flex } from '@chakra-ui/layout';
import { Button, Show } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useTranslation } from 'react-i18next';
import useUpdateUser from '../hooks/useUpdateUser';
import ProfileHeader from './header';
const PersonalFields = [
  {
    name: 'firstName',
    label: 'profile_settings.firstName',
    id: 'firstName',
    placeholder: 'John',
  },
  {
    name: 'lastName',
    label: 'profile_settings.lastName',
    id: 'lastName',
    placeholder: 'William',
  },
  {
    name: 'email',
    label: 'profile_settings.email_address',
    id: 'email',
    placeholder: 'mail@simmmple.com',
  },
  {
    name: 'jobTitle',
    label: 'profile_settings.job_title',
    id: 'jobTitle',
    placeholder: 'profile_settings.what_do_you_do',
  },
];
export default function PersonalSettings() {
  const { t } = useTranslation();
  const user = useMyUser();
  const {
    inputValues,
    updateUser,
    handleChange,
    isSaveBtnVisible,
    setIsSaveBtnVisible,
    loading,
    setLoading,
  } = useUpdateUser(user);
  return (
    <Card
      flexDirection="column"
      w="100%"
      p="0"
      ps={'20px'}
      pe={{ sm: '26px', md: '42px' }}
      maxW={{ base: '100%', md: '720px' }}
      borderRadius={{ base: 0, md: '30px' }}
      role={!isSaveBtnVisible ? 'group' : null}>
      <ProfileHeader
        isSaveBtnVisible={isSaveBtnVisible}
        updateUser={updateUser}
        loading={loading}
        setLoading={setLoading}
        userId={user?.id}
        userMedia={user?.media}
        handleChange={handleChange}
        setIsSaveBtnVisible={setIsSaveBtnVisible}
        fullName={`${user?.firstName} ${user?.lastName}`}
      />
      <Flex wrap="wrap" gap={8} my={'30px'}>
        {PersonalFields.map((field, key) => (
          <InputField
            flex="1"
            w={{ base: '100%', md: '310px' }}
            h="50px"
            key={key}
            value={(inputValues && inputValues[field?.name]) || ''}
            name={field?.name}
            onChange={handleChange}
            label={t(field?.label)}
            placeholder={t(field?.placeholder)}
            labelProps={{ ps: '10px', fontWeight: '500' }}
            disabled={!isSaveBtnVisible}
          />
        ))}
        <TextField
          label={t('profile_settings.about_me')}
          placeholder={t(
            'profile_settings.tell_something_about_yourself_in_150_characters',
          )}
          resize={'none'}
          w={{ base: '100%', md: '310px' }}
          minH="131px"
          labelProps={{ fontWeight: '500', color: 'primary.100' }}
          name="aboutMe"
          value={inputValues?.aboutMe || ''}
          onChange={handleChange}
          color="primary.100"
        />
        <Show below="md">
          {isSaveBtnVisible && (
            <Button
              variant="h1cta"
              onClick={() => {
                updateUser();
              }}
              isLoading={loading}
              minW="100%">
              {t('profile_settings.save_changes')}
            </Button>
          )}
        </Show>
      </Flex>
    </Card>
  );
}
