import { Button, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import { IoIosAlert } from 'react-icons/io';
import CreateEventContactDetails from './components/CreateEventContactDetails';
import CreateEventDetails from './components/CreateEventDetails';
import CreateEventHeader from './components/CreateEventHeader';
import CreateEventPlanner from './components/CreateEventPlanner';
import EventFound from './components/EventFound';
import { EventFoundModal } from './components/EventFoundModal';
import useCreateEvent from './hooks/useCreateEvent';
export default function Events() {
  const { handleChange, handleCreateEvent, event, errorList } =
    useCreateEvent();
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      flexDirection="column"
      gap={4}>
      <Card gap={5} maxW="6xl">
        <CreateEventHeader
          handleChange={handleChange}
          handleCreateEvent={handleCreateEvent}
          event={event}
          errorList={errorList}
        />
        <CreateEventContactDetails
          handleChange={handleChange}
          event={event}
          errorList={errorList}
        />
        <CreateEventPlanner handleChange={handleChange} event={event} />
        <CreateEventDetails
          handleChange={handleChange}
          event={event}
          errorList={errorList}
        />
        <EventFound event={event} />
        <VStack
          w="100%"
          align="center"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          justifyContent={{ base: 'center', md: 'end' }}
          gap="20px"
          h="auto">
          {errorList.length > 0 && (
            <HStack>
              <Icon
                as={IoIosAlert}
                w="16px"
                h="16px"
                bg="white"
                color="error.100"
                borderRadius="50%"
              />
              <Text variant="errorTitle">{t('create_event.error')}</Text>
            </HStack>
          )}
          <Button
            variant="h1cta"
            // px={{ base: '16px', sm: '24px' }}
            w={{ base: '100%', md: '171px' }}
            disabled={errorList.length > 0}
            mt="0px !important"
            onClick={handleCreateEvent}>
            {t('create_event.save_and_close')}
          </Button>
        </VStack>
      </Card>
      <EventFoundModal onClose={() => {}} />
    </Flex>
  );
}
