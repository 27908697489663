import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import EventIcon from 'assets/svg/fab/calendar-fab-icon.svg';
import InvoiceIcon from 'assets/svg/fab/invoice-fab-icon.svg';
import MessagesIcon from 'assets/svg/fab/messages-fab-icon.svg';
import QuoteIcon from 'assets/svg/fab/quote-fab-icon.svg';
import TaskIcon from 'assets/svg/fab/task-fab-icon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const actions = [
  {
    title: 'Quote',
    value: 'quote',
    icon: QuoteIcon,
  },
  {
    title: 'Task',
    value: 'task',
    icon: TaskIcon,
  },
  {
    title: 'Meeting',
    value: 'meeting',
    icon: MessagesIcon,
  },
  {
    title: 'Event',
    value: 'event',
    icon: EventIcon,
  },
  {
    title: 'Invoice',
    value: 'invoice',
    icon: InvoiceIcon,
  },
];

export default function Default() {
  // The Fab is the main button. Pass any component to the icon prop and choose
  // either click or hover for the event (default is hover)
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const components = useMemo(
    () => [
      {
        position: {
          right: i18n.dir() === 'ltr' ? 20 : 'auto',
          left: i18n.dir() === 'ltr' ? 'auto' : 50,
          bottom: 0,
        },
        event: 'hover', // hover/click

        alwaysShowTitle: true, //false
        mainButtonStyles: {
          backgroundColor: '#fff',
        },
        actionButtonStyles: {
          backgroundColor: '#fff',
        },
      },
    ],
    [i18n.language],
  );

  const handleOnClick = useCallback(
    (action: string) => {
      switch (action) {
        case 'invoice':
          navigate(`/main/managment/invoices`);
          break;
        case 'event':
          navigate(`/main/managment/events`);
          break;
        case 'meeting':
          navigate(`/main/managment/meetings`);
          break;
        case 'task':
          navigate(`/main/tasks`);
          break;
        case 'quote':
          navigate(`/main/managment/quotes`);
          break;
        default:
          console.log('No action');
      }
    },
    [navigate],
  );

  const renderComponents = (c: any) =>
    c.map(
      (
        {
          mainButtonStyles,
          actionButtonStyles,
          position,
          event,
          alwaysShowTitle,
        }: any,
        i: number,
      ) => (
        <Fab
          mainButtonStyles={mainButtonStyles}
          style={position}
          icon={<Icon fontSize={24} color="brand.900" as={FiPlus} />}
          event={event}
          key={i}
          alwaysShowTitle={alwaysShowTitle}>
          {actions.map((action, i) => (
            <Action
              key={i}
              // text={action.title}
              style={{
                ...actionButtonStyles,
                display: 'flex',
                width: 'fit-content',
                dir: 'ltr',
                height: '40px',
                borderRadius: '30px',
                position: 'relative',
              }}
              onClick={() => handleOnClick(action.value)}>
              <Flex
                position={'relative'}
                _hover={{
                  width: 'fit-content',
                }}
                dir={'ltr'}
                width={10}
                align="center"
                role="group"
                transition="all 0.2s">
                <Text
                  fontWeight={'300'}
                  ps={4}
                  pe={4}
                  fontSize="sm"
                  textAlign={'center'}
                  opacity={1}
                  _groupHover={{
                    display: 'block',
                    opacity: 1,
                  }}
                  color="primary.100"
                  display={'none'}>
                  {t(`fab.${action.value}`)}
                </Text>
                <Flex h={'40px'} w={'40px'} justify={'center'} align="center">
                  <Image
                    position={'relative'}
                    display="flex"
                    minW={6}
                    src={action.icon}
                    w={6}
                    h={6}
                  />
                </Flex>
              </Flex>
            </Action>
          ))}
        </Fab>
      ),
    );
  return renderComponents(components);
}
