import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEvent } from 'services/@types';

export interface AppDialogProps {
  title?: string;
  content?: string;
  item?: any;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: (p?: any) => void;
  onClose?: () => void;
  event?: IEvent;
}
export enum DialogKeys {
  addVideoLinkDialog = 'addVideoLinkDialog',
  addPhotoDialog = 'addPhotoDialog',
}
interface DialogsState {
  alertDialog: undefined | AppDialogProps;
  splashDialog: undefined | AppDialogProps;
  splashDialogTimeoutId?: NodeJS.Timeout;
  supplierFoundDialog: undefined | AppDialogProps;
  addPhotoDialog: undefined | AppDialogProps;
  addVideoLinkDialog: undefined | AppDialogProps;
  viewPhotoDialog: undefined | AppDialogProps;
  addSeatDialog: undefined | AppDialogProps;
  addAlbumDialog: undefined | AppDialogProps;
  eventFoundDialog: undefined | AppDialogProps;
  askForQuoteDialog: undefined | AppDialogProps;
  eventsDialog: undefined | AppDialogProps;
  productCategoriesDialog: undefined | AppDialogProps;
  requestQuoteDialog: undefined | AppDialogProps;
}

const initialState: DialogsState = {
  alertDialog: undefined,
  splashDialog: undefined,
  supplierFoundDialog: undefined,
  addPhotoDialog: undefined,
  addVideoLinkDialog: undefined,
  viewPhotoDialog: undefined,
  addSeatDialog: undefined,
  addAlbumDialog: undefined,
  eventFoundDialog: undefined,
  askForQuoteDialog: undefined,
  eventsDialog: undefined,
  productCategoriesDialog: undefined,
  requestQuoteDialog: undefined,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openAlertDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.alertDialog = action.payload;
    },
    closeAlertDialog: (state) => {
      state.alertDialog = undefined;
      state.splashDialogTimeoutId = undefined;
    },
    setSplashDialogTimeoutId: (
      state,
      action: PayloadAction<NodeJS.Timeout>,
    ) => {
      state.splashDialogTimeoutId = action.payload;
    },
    clearSplashDialogTimeoutId: (state) => {
      state.splashDialogTimeoutId = undefined;
    },
    setSplashDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.splashDialog = action.payload;
    },
    closeSplashDialog: (state) => {
      state.splashDialog = undefined;
      if (state.splashDialogTimeoutId) {
        clearTimeout(state.splashDialogTimeoutId);
        state.splashDialogTimeoutId = undefined;
      }
    },
    setSupplierFoundDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.supplierFoundDialog = action.payload;
    },
    closeSupplierFoundDialog: (state) => {
      state.supplierFoundDialog = undefined;
    },
    setAddPhotoDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addPhotoDialog = action.payload;
    },
    closeAddPhotoDialog: (state) => {
      state.addPhotoDialog = undefined;
    },
    setViewPhotoDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.viewPhotoDialog = action.payload;
    },
    closeViewPhotoDialog: (state) => {
      state.viewPhotoDialog = undefined;
    },
    setAddSeatDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addSeatDialog = action.payload;
    },
    closeAddSeatDialog: (state) => {
      state.addSeatDialog = undefined;
    },
    setAddAlbumDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.addAlbumDialog = action.payload;
    },
    closeAddAlbumDialog: (state) => {
      state.addAlbumDialog = undefined;
    },
    setAddVideoLinkModal: (state, action: PayloadAction<AppDialogProps>) => {
      state.addVideoLinkDialog = action.payload;
    },
    closeAddVideoLinkModal: (state) => {
      state.addVideoLinkDialog = undefined;
    },
    setEventFoundDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.eventFoundDialog = action.payload;
    },
    closeEventFoundDialog: (state) => {
      state.eventFoundDialog = undefined;
    },
    setAskForQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.askForQuoteDialog = action.payload;
    },
    closeAskForQuoteDialog: (state) => {
      state.askForQuoteDialog = undefined;
    },
    setEventsDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.eventsDialog = action.payload;
    },
    closeEventsDialog: (state) => {
      state.eventsDialog = undefined;
    },
    setProductCategoriesDialog: (
      state,
      action: PayloadAction<AppDialogProps>,
    ) => {
      state.productCategoriesDialog = action.payload;
    },
    closeProductCategoriesDialog: (state) => {
      state.productCategoriesDialog = undefined;
    },
    setRequestQuoteDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.requestQuoteDialog = action.payload;
    },
    closeRequestQuoteDialog: (state) => {
      state.requestQuoteDialog = undefined;
    },
  },
});

export const {
  closeAlertDialog,
  openAlertDialog,
  closeSplashDialog,
  setSplashDialog,
  setSplashDialogTimeoutId,
  clearSplashDialogTimeoutId,
  setSupplierFoundDialog,
  closeSupplierFoundDialog,
  setAddPhotoDialog,
  closeAddPhotoDialog,
  setViewPhotoDialog,
  closeViewPhotoDialog,
  setAddSeatDialog,
  closeAddSeatDialog,
  setAddAlbumDialog,
  closeAddAlbumDialog,
  setAddVideoLinkModal,
  closeAddVideoLinkModal,
  setEventFoundDialog,
  closeEventFoundDialog,
  setAskForQuoteDialog,
  closeAskForQuoteDialog,
  setEventsDialog,
  closeEventsDialog,
  setProductCategoriesDialog,
  closeProductCategoriesDialog,
  closeRequestQuoteDialog,
  setRequestQuoteDialog,
} = dialogsSlice.actions;

export const showSplashDialog =
  (dialogProps: AppDialogProps): any =>
  (dispatch: any, getState: any) => {
    const timeoutId = setTimeout(() => {
      console.log(
        '# dialogs splash dialog timeout id -> ',
        getState().dialogs.splashDialogTimeoutId,
      );
      if (getState().dialogs.splashDialogTimeoutId) {
        dispatch(setSplashDialog(dialogProps));
        return;
      }
      dispatch(clearSplashDialogTimeoutId());
    }, 400);

    dispatch(setSplashDialogTimeoutId(timeoutId));
  };

export default dialogsSlice.reducer;
